// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_background__lSVAk {
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  background-color: rgba(255, 255, 255, 0.9) !important;
  color: #1f1f1f !important;
}

.styles_logoAppBar__YRnuX img {
  object-fit: contain;
  max-height: 50px;
}

.styles_login__4bomR {
  color: #1f1f1f;
}

.styles_logoutButton__cSXAH {
  color: rgb(153, 153, 153) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TopBar/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;UAAA,0BAAA;EACA,qDAAA;EACA,yBAAA;AACJ;;AAGI;EACI,mBAAA;EACA,gBAAA;AAAR;;AAIA;EACI,cAAA;AADJ;;AAIA;EACI,oCAAA;AADJ","sourcesContent":[".background {\r\n    backdrop-filter: blur(8px);\r\n    background-color: rgba(255, 255, 255, 0.9) !important;\r\n    color: #1f1f1f !important;\r\n}\r\n\r\n.logoAppBar {\r\n    img {\r\n        object-fit: contain;\r\n        max-height: 50px;\r\n    }\r\n}\r\n\r\n.login {\r\n    color: #1f1f1f;\r\n}\r\n\r\n.logoutButton {\r\n    color: rgb(153, 153, 153) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `styles_background__lSVAk`,
	"logoAppBar": `styles_logoAppBar__YRnuX`,
	"login": `styles_login__4bomR`,
	"logoutButton": `styles_logoutButton__cSXAH`
};
export default ___CSS_LOADER_EXPORT___;
