import { Box, Grid } from "@mui/material"
import styles from './styles.module.scss'

export default function Error() {
    return (
        <Box className={styles.background}>
            <Grid container justifyContent="center">
                <h1>404 ERROR</h1>
            </Grid>
        </Box>
    )
}