import { Box, Grid, Button } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import doodle from '../../assets/app.webp'
import InputMask from 'react-input-mask';
import JsBarcode from 'jsbarcode';
import api from "../../services/api_credenciamento";

export default function Credenciamento() {
    const [cpfCnpj, setCpfCnpj] = useState("");

    useEffect(()=>{
    },[])

    function handleInputCpfCnpj(e) {
        // alert(e.target.value.replace(/[^0-9]/g, ''))
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }
    function addCpfCnpj(e) {
        setCpfCnpj(cpfCnpj + e.target.value);
    }
    function deleteCpfCnpj(e) {
        setCpfCnpj(cpfCnpj.substr(0, cpfCnpj.length - 1));
    }
    function clearCpfCnpj(e) {
        setCpfCnpj("");
    }
    function callVerifyUser() {
        VerifyUser(cpfCnpj)
    }
    
    async function PrintJS (cpf_cnpj, nome, agencia, tipo_usuario) {
        
        if (typeof window !== "undefined") {
            // Acesso(cpf_cnpj); //REGISTRAR O ACESSO DO VISITANTE
            const printJS = require('print-js');
    
            const someJSONdata = [
                {
                    // pa: agencia,
                },
            ]
    
            var nome_split = nome.split(" ");
            var nome_impressao = nome;
            if(nome_split.length >= 2) {
                nome_impressao = nome_split[0]+" "+nome_split[nome_split.length-1];
            }
    
    
            var associado_impressao = tipo_usuario;
    
            const { DOMImplementation, XMLSerializer } = require('xmldom');
            const xmlSerializer = new XMLSerializer();
            const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
            const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    
            JsBarcode(svgNode, cpf_cnpj, {
                xmlDocument: document,
                displayValue: false,
                height: 15,
                textMargin: 0,
                marginTop: 0,
                background: 'transparent',
            });
    
            const svgText = xmlSerializer.serializeToString(svgNode);
    
            const descricao = agencia !== "" ?
                    '<div style="margin-top: -10px">\
                    <p style="margin: 0; padding: 0;font-family: sans-serif; font-size: 10px; line-height: 0;">'+agencia+'</p></br>\
                    <p style="margin: 0; padding: 0;font-style: italic;font-family: sans-serif; font-size: 10px; line-height: 0;">'+associado_impressao+'</p>\
                    </div>' : 
                    '<div style="margin-top: -10px">\
                    <p style="margin: 0; padding: 0;font-style: italic;font-family: sans-serif; font-size: 10px; line-height: 0;">'+associado_impressao+'</p>\
                    </div>'
            
            await printJS({
                printable: someJSONdata,
                properties: [],
                header: '<div style="z-index: 999999; left: 0; margim: 0; text-align: center; width: 100%; background-color: transparente !important;">\
                            <div style="top: 0">'+svgText+'</div>\
                            <p style="font-weight: bold;margin-top: 0px; padding: 0;font-family: sans-serif; text-transform: uppercase; font-size: 15px; line-height: 0; text-align: center" margin:auto;>'
                                +nome_impressao+
                            '</p></br>'
                            +descricao+
                        '</div>',
                type: 'json'})
        }
    }
    
    async function registrarCracha() {
        api
        .post("/registrar-cracha", {
            cpf_cnpj: cpfCnpj
        })
        .then((res) => {
            if(res.data) {
                const response = res.data;
                
                if(response.erro === 0) {
    
                } else {
                    console.error("Erro ao emitir cracha, tente novamente")
                }
            } else {
                    console.error("Erro ao emitir cracha, tente novamente")
                
            }
    
            
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
      }
    
    async function VerifyUser (cpfCnpj) {
        if(cpfCnpj === '') {
            // setUserRegistered("");
            alert("Informe um CPF ou CNPJ");
            return;
        }
        if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
            // setUserRegistered("");
            alert("Informe um CPF ou CNPJ completo");
            return;
        }
        
        api
        .post("/verifyUser", {
            cpf_cnpj: cpfCnpj
        })
        .then((res) => {
            if(res.data) {
                const response = res.data;
                
                if(response.qtd > 0) {
                    var nome = response.data[0].nome;
                    var cpf_cnpj = response.data[0].cpf_cnpj;
                    var agencia = response.data[0].cpf_gerente != null ? response.data[0].nome_agencia : response.data[0].cidade;
                    // setUserRegistered("true");
                    var type_user = ""; 
                    response.data[0].types_user.map((user_type) => {
                        if(user_type.user_type === 3 || user_type.user_type === 5) {
                            type_user = "SICOOB CREDCOOPER";
                            agencia = "";
                        } else if ((user_type.user_type === 2 || user_type.user_type === 4) && type_user !== "SICOOB CREDCOOPER") {
                            type_user = "EXPOSITOR";
                            agencia = "";
                        }
                    });
                    var tipo_usuario = type_user !== "" ? type_user : response.data[0].cpf_gerente !== null ? "ASSOCIADO" : "";
                    registrarCracha()
                    PrintJS(cpf_cnpj, nome, agencia, tipo_usuario);
                    setCpfCnpj("");
                } else {
                    alert("Visitante nao credenciado, favor se direcionar ao credenciamento")
                    setCpfCnpj("");
                }
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }

    return (
        <Box className={styles.background}>
            <Grid md={12} className={styles.doddle}>
                <img src={doodle} alt="" />
            </Grid>
            <Grid container justifyContent="center">
                <Grid xs={10} md={4}>
                    <Grid container className={styles.boxCredenciamento}>
                        <Grid md={12} spacing={2} className={styles.formCredenciamento}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <InputMask 
                                        mask={cpfCnpj.length < 12 ? "999.999.999-99" : "99.999.999/9999-99"}
                                        maskChar=""
                                        value={cpfCnpj}
                                        placeholder='INSIRA SEU CPF/CNPJ'
                                        disabled/>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="1" onClick={addCpfCnpj}>1</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="2" onClick={addCpfCnpj}>2</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="3" onClick={addCpfCnpj}>3</Button>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="4" onClick={addCpfCnpj}>4</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="5" onClick={addCpfCnpj}>5</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="6" onClick={addCpfCnpj}>6</Button>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="7" onClick={addCpfCnpj}>7</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="8" onClick={addCpfCnpj}>8</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="9" onClick={addCpfCnpj}>9</Button>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2}>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTeclado} margin="large" value="0" onClick={addCpfCnpj}>0</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTecladoApagar} margin="dense" onClick={deleteCpfCnpj}>APAGAR</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button fullWidth variant="contained" size="large" className={styles.buttonTecladoLimpar} margin="dense" onClick={clearCpfCnpj}>LIMPAR</Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonCredenciamento} margin="dense" onClick={callVerifyUser}>CONFIRMAR</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}