import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PaginaInicial from "../../../components/Painel/Expositor/PaginaInicial";
import PaginaProdutos from "../../../components/Painel/Expositor/PaginaProdutos";
import PaginaOrcamentos from "../../../components/Painel/Expositor/PaginaOrcamentos";
import PaginaFuncionarios from "../../../components/Painel/Expositor/PaginaFuncionarios";

export default function RoutesPainel() {
    return (
        <>
            <Routes>
                <Route path="/" element={<PaginaInicial/>} exact />
                <Route path="/produtos" element={<PaginaProdutos/>} exact />
                <Route path="/orcamentos" element={<PaginaOrcamentos/>} exact />
                <Route path="/funcionarios" element={<PaginaFuncionarios/>} exact />
                <Route path="*" element={<PaginaInicial/>} />
            </Routes>
        </>
    )
}
