import { Box, Grid, TextField, Button } from "@mui/material"
import styles from './styles.module.scss'
import React, { useState, useEffect } from "react";
import doodle from '../../assets/gado.webp'
import { Link } from "react-router-dom";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import api from "../../services/api";
import ImputMask from 'react-input-mask'

export default function LoginNovo() {
    const [rowSelection, setRowSelection] = useState(2);
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [senha, setSenha] = useState("");
    
    useEffect(()=>{
        
    },[])
    
   
    async function LoginExpositor() {
        try {
            var alerta = "";
            if(cpfCnpj === "" || cpfCnpj === null) {
                alerta += "CPF/CNPJ\n";
            }
            if(senha === "" || senha === null) {
                alerta += "Senha\n";
            }
            if(alerta !== "") {
                alert("Favor informar os dados básicos necessários:\n" + alerta);
                return false;
            }
            if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
                alert("Informe um CPF ou CNPJ completo");
                return;
            }
            if(!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
                alert('CPF/CNPJ inválido');
                return;
            }
        
            api
            .post("/login/expositor", {
                cpf_cnpj: cpfCnpj,
                senha: senha
            })
            .then((res) => {
                const response = res.data;
                
                if(response.erro == 0 && response.qtd > 0) {
                    // setCookie("token", response.data.token);
                    const userType = response.data.user_type.toString()
                    const userId = response.data.id.toString()
                    // const exp = response.data.exp.toString()
                    setCookie("userType", userType);
                    setCookie("userId", userId);
                    setCookie("userNome", userNome);
                    window.location.href = "/painel-expositor";
                } else if(response.erro != 0 && response.qtd == 0) {
                    alert("Usuário ou senha inválida");
                } else {
                    alert("Ocorreu um erro ao verificar o usuário, tente novamente.")
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.log(e.message)
        }
    };
    async function LoginColaborador() {
        try {
            var alerta = "";
            {/*if(cpfCnpj === "" || cpfCnpj === null) {
                alerta += "CPF/CNPJ\n";
            }
            if(senha === "" || senha === null) {
                alerta += "Senha\n";
            }
            if(alerta !== "") {
                alert("Favor informar os dados básicos necessários:\n" + alerta);
                return false;
            }
            if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
                alert("Informe um CPF ou CNPJ completo");
                return;
            }
            if(!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
                alert('CPF/CNPJ inválido');
                return;
            }*/}
        
            try {
                const [results, fields] = await connection.query(
                    'SELECT * FROM `usuario_colaborador` WHERE `cpf` = "13334914682"'
                );
    
                alert(results);
            } catch (err) {
                console.log(err);
            }
        } catch (e) {
          console.log(e.message)
        }
    };
    
    function callLogin() {
        if(rowSelection === 2) {
            LoginExpositor();
        } else if(rowSelection === 3) {
            LoginColaborador();
        }
    }

    function handleInputCpfCnpj(e) {
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }

    function handleInputSenha(e) {
        setSenha(e.target.value);
    }

    return (
        <Box className={styles.background}>
            <Grid md={12} className={styles.doddle}>
                <img src={doodle} alt="" />
            </Grid>
            <Grid container justifyContent="center">
                <Grid xs={10} md={8}>
                    <Grid container className={styles.boxLogin}>
                        <Grid xs={12} md={4} className={styles.typeLogin}>
                            <Grid container>
                                {/* <Grid item xs={12} md={12} 
                                    className={[styles.buttonLogin, rowSelection === 1 ? styles.active : '']}
                                    onClick={() => {
                                        setRowSelection(1)
                                    }}
                                >
                                    <span>Associado/Visitante</span>
                                </Grid> */}
                                <Grid item xs={6} md={12} 
                                    className={[styles.buttonLogin, rowSelection === 2 ? styles.active : '']}
                                    onClick={() => {
                                        setRowSelection(2)
                                    }}
                                >
                                    <span>Expositor</span>
                                </Grid>
                                <Grid item xs={6} md={12} 
                                    className={[styles.buttonLogin, rowSelection === 3 ? styles.active : '']}
                                    onClick={() => {
                                        setRowSelection(3)
                                    }}
                                >
                                    <span>Colaborador Credcooper</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={8} className={styles.formLogin}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <h1 className={styles.titleLogin}>Acesso ao sistema FENASC</h1>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth  label="CPF/CNPJ" variant="outlined" margin="dense" onChange={handleInputCpfCnpj}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField fullWidth  type="password" label="Senha" variant="outlined" margin="dense" onChange={handleInputSenha}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonLogin} margin="dense" onClick={callLogin}>ACESSAR</Button>
                                </Grid>
                                <Grid item xs={12} md={12} style={{display: rowSelection === 1 ? 'block' : 'none'}}>
                                    <Link to="/cadastro">
                                        <Button fullWidth variant="contained" size="small" className={styles.buttonCadastro} margin="dense">CRIAR CONTA</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}