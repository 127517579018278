// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/sicoob_sans/SicoobSansRegular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/sicoob_sans/SicoobSansThin.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/sicoob_sans/SicoobSansBlack.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/sicoob_sans/SicoobSansBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/sicoob_sans/SicoobSansSemibold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, .global_App__uINxF {
  min-height: 100% !important;
}

body > div:first-child {
  min-height: 100% !important;
}

@font-face {
  font-family: "Sicoob";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "Sicoob Thin";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: "Sicoob Black";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: "Sicoob Bold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
  font-family: "Sicoob SemiBold";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
.global_componentRoute__FGht9 {
  padding-top: 65px !important;
  height: 100%;
}

.global_componentRouteHideTopBar__pzjOO {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/global.module.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AACA;EACI,2BAAA;AAEJ;;AACA;EACA,qBAAA;EACA,4CAAA;AAEA;AAAA;EACA,0BAAA;EACA,4CAAA;AAEA;AAAA;EACA,2BAAA;EACA,4CAAA;AAEA;AAAA;EACA,0BAAA;EACA,4CAAA;AAEA;AAAA;EACA,8BAAA;EACA,4CAAA;AAEA;AACA;EACI,4BAAA;EACA,YAAA;AACJ;;AACA;EACI,YAAA;AAEJ","sourcesContent":["html, body, .App {\r\n    min-height: 100% !important;\r\n}\r\nbody>div:first-child{\r\n    min-height: 100% !important;\r\n}\r\n\r\n@font-face {\r\nfont-family: \"Sicoob\";\r\nsrc: url(\"./assets/sicoob_sans/SicoobSansRegular.woff2\");\r\n}\r\n@font-face {\r\nfont-family: \"Sicoob Thin\";\r\nsrc: url(\"./assets/sicoob_sans/SicoobSansThin.woff2\");\r\n}\r\n@font-face {\r\nfont-family: \"Sicoob Black\";\r\nsrc: url(\"./assets/sicoob_sans/SicoobSansBlack.woff2\");\r\n}\r\n@font-face {\r\nfont-family: \"Sicoob Bold\";\r\nsrc: url(\"./assets/sicoob_sans/SicoobSansBold.woff2\");\r\n}\r\n@font-face {\r\nfont-family: \"Sicoob SemiBold\";\r\nsrc: url(\"./assets/sicoob_sans/SicoobSansSemibold.woff2\");\r\n}\r\n\r\n.componentRoute {\r\n    padding-top: 65px !important;\r\n    height: 100%;\r\n}\r\n.componentRouteHideTopBar {\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `global_App__uINxF`,
	"componentRoute": `global_componentRoute__FGht9`,
	"componentRouteHideTopBar": `global_componentRouteHideTopBar__pzjOO`
};
export default ___CSS_LOADER_EXPORT___;
