import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Error from "./pages/Error";
import Home from "./pages/Home";
import Login from "./pages/Login";
import LoginNovo from "./pages/LoginNovo";
import Cadastro from "./pages/Cadastro";
import Credenciamento from "./pages/Credenciamento";
import PainelExpositor from "./pages/Painel/Expositor";
import PainelFuncionario from "./pages/Painel/Funcionario";
import FotoPersolizada from "./pages/Foto";
import ValidarCredenciamento from './components/Painel/Funcionario/PaginaValidarCredenciamento';
import AcompanhamentoCupons from "./pages/AcompanhamentoCupons";

export default function MainRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home/>} exact />
            <Route path="/login" element={<Login/>} exact />
            <Route path="/login-novo" element={<LoginNovo/>} exact />
            <Route path="/cadastro" element={<Cadastro/>} exact />
            <Route path="/foto" element={<FotoPersolizada/>} exact />
            <Route path="/credenciamento" element={<Credenciamento/>} exact />
            <Route path="/validar-credenciamento" element={<ValidarCredenciamento/>} exact />
            <Route path="/painel-expositor/*" element={<PainelExpositor/>} exact/>
            <Route path="/cupons" element={<AcompanhamentoCupons/>} exact/>
            <Route path="/painel-colaborador/*" element={<PainelFuncionario/>} exact/>
            <Route path="*" element={<Error/>} />
        </Routes>
    )
}
