import React, { useEffect } from "react";
import TopBar from '../src/components/TopBar'
import MainRoutes from './Routes'
import { Grid } from "@mui/material"
import styles from './global.module.scss'
import { useLocation } from 'react-router-dom'
import DocumentMeta from "react-document-meta";

function App() {
  const location = useLocation();
  
  const meta = {
    title: 'Some Meta Title',
    description: 'I am a description, and I can create multiple tags',
    canonical: 'http://example.com/path/to/page',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'react,meta,document,html,tags'
        }
    }
  }
 
  useEffect(() => {
        routeHttp();
        document.title = 'FENASC';
  }, []);
  
  async function routeHttp() {
    const currentUrl = window.location.href;
    if(currentUrl.search("https") >= 0) {
        // window.location.href = currentUrl.replace("http", "https")
    }
  }

  return (
    <>
      {
        location.pathname === "/credenciamento" || location.pathname === "/cupons" ? 
          <div className={styles.App}>
            <div className={styles.hideTopBar}></div>
            <Grid className={styles.componentRouteHideTopBar}>
              <MainRoutes/>
            </Grid>
          </div>
          :
          <div className={styles.App}>
            <TopBar></TopBar>
            <Grid className={styles.componentRoute}>
              <MainRoutes/>
            </Grid>
          </div>
        }
    </>
  );
}

export default App;