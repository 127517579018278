import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PaginaInicial from "../../../components/Painel/Funcionario/PaginaInicial";
import PaginaOrcamentos from "../../../components/Painel/Funcionario/PaginaOrcamentos";
import PaginaFotoExpositor from "../../../components/Painel/Funcionario/PaginaFotoExpositor";
import PaginaFuncionarios from "../../../components/Painel/Funcionario/PaginaFuncionarios";
import PaginaExpositores from "../../../components/Painel/Funcionario/PaginaExpositores";
import PaginaNegociacao from "../../../components/Painel/Funcionario/PaginaNegociacao";
import PaginaSorteador from "../../../components/Painel/Funcionario/PaginaSorteador";
import PaginaValidarCredenciamento from "../../../components/Painel/Funcionario/PaginaValidarCredenciamento";

export default function RoutesPainel() {
    return (
        <>
            <Routes>
                <Route path="/" element={<PaginaInicial/>} exact />
                <Route path="/orcamentos" element={<PaginaOrcamentos/>} exact />
                <Route path="/funcionarios" element={<PaginaFuncionarios/>} exact />
                <Route path="/expositores" element={<PaginaExpositores/>} exact />
                <Route path="/validar-credenciamento" element={<PaginaValidarCredenciamento/>} exact />
                <Route path="/negociacao" element={<PaginaNegociacao/>} exact />
                <Route path="/sorteador" element={<PaginaSorteador/>} exact />
                <Route path="/foto-expositor" element={<PaginaFotoExpositor/>} exact />
                <Route path="*" element={<PaginaInicial/>} />
            </Routes>
        </>
    )
}
