import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel,Box } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import api from "../../../../services/api";

export default function PaginaProdutosExpositor() {
    const [fileBase64, setFileBase64]= useState("");
    const [file, setFile]= useState("");
    const [files, setFiles]= useState([]);

    useEffect(()=>{
        getFotos();
    },[])

    async function handleInputPicture(e) {
        const file = e.target.files[0]
        if(file) {
            if (file.type.search('image') < 0) {
                alert("Arquivo inválido, permitido somente imagens");
            } else {
                var reader = new FileReader();
                var fileBase64 = null;
                reader.readAsDataURL(file);
                reader.onload = async function () {
                    fileBase64 = reader.result;
                    setFileBase64(fileBase64);
                    setFile(file);
                };
                
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    setFileBase64("");
                    setFile("");
                };
            }
        } else {
            setFileBase64("");
            setFile("");
        }
    }

    async function excluirFoto(e) {
        await api
        .delete("/expositor/logo/"+e.target.value)
        .then((res) => {
            const response = res.data;
            if(response.erro === 0) {
                getFotos();
            } else if(response.erro !== 0) {
                alert(response.mensagem);
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function salvarFoto() {
        if(file === "") {
            alert("Selecione a foto que deseja enviar");
            return
        }

        const formData = new FormData();
        formData.append('file', file);
        
        await api
        .post("/expositor/logo", formData, { 
            headers: {
                "Content-Type": `multipart/form-data`
            }
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                alert('Logo cadastrada com sucesso!')
                setFile("");
                setFileBase64("");
                getFotos();
            } else if(response.erro !== 0) {
                alert(response.mensagem);
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function getFotos() {
        await api
        .get("/expositor/logo")
        .then((res) => {
            const response = res.data;
            setFiles(response)
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    return (
        <Grid container>
            <Grid item xs={12} md={3} className={styles.cadastroProduto}>
                <Grid container spacing={2} className={[styles.cadastroProdutoSection]}>
                    <Grid item xs={12} md={12}>
                        <div className={styles.imagemProduto}>
                            <div
                                id="img-logo-expositor"
                                className={styles.imgImageExpositor}
                                style={{backgroundImage: "url("+fileBase64+")"}}
                            ></div>
                        </div>
                        <label htmlFor="foto-expositor" className={styles.labelFile}>ENVIAR FOTO</label>
                        <input type="file" id="foto-expositor" name="" className={styles.inputFile} onChange={handleInputPicture}/>                     
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} margin="dense" disabled={file === ""} onClick={salvarFoto}>SALVAR</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={9}>
                <Grid container className={styles.boxLogosExpositor}>
                    {files.map((logo) => {
                        return <Grid className={styles.boxLogoExpositor} xs={6} md={2}>
                            <Grid item
                            className={styles.logoExpositor}
                            style={{backgroundImage: "url("+logo.url+")"}}
                        ></Grid>
                            <Button value={logo.name} onClick={excluirFoto}>Excluir</Button>
                        </Grid>
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}