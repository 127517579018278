import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import styles from './styles.module.scss'
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';

function TopBar() {

    function logout() {
      deleteCookie('userNome');
      deleteCookie('userId');
      deleteCookie('userType');
      window.location.href = "/";
    }

    return (
      <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" elevation={0} className={styles.background}>
            <Toolbar>
              <Box component="div" sx={{ flexGrow: 1 }}>
                <Link to="/" className={styles.logoAppBar}>
                  <img src={logo} className={styles.logoAppBar}/>
                </Link>
              </Box>
              {
                getCookie('userType') !== undefined ?
                <>
                  <Link to={getCookie('userType') == 2 || getCookie('userType') == 4 ? '/painel-expositor' : '/painel-colaborador'} className={styles.login}>
                    <Button color="inherit">Painel</Button>
                  </Link>
                  <Button onClick={logout} className={styles.logoutButton}>Sair</Button>
                </>
                
                :
                <Link to="/login" className={styles.login}>
                  <Button color="inherit">Login</Button>
                </Link>
              }
            </Toolbar>
          </AppBar>
        </Box>
    )
}
export default TopBar;