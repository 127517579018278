import { Box, Container, Grid } from "@mui/material"
import styles from './styles.module.scss'
import video from '../../assets/fenasc2023.mp4'
import icon1 from '../../assets/icons/5.png'
import icon2 from '../../assets/icons/3.png'
import icon3 from '../../assets/icons/2.png'
import img1 from '../../assets/img1.webp'
import img2 from '../../assets/img2.webp'
import img3 from '../../assets/img3.webp'
import Clock from '../../components/Clock'
import { useEffect, useState } from "react"
import api from "../../services/api";

export default function Home() {
    const [files, setFiles]= useState([]);

    useEffect(()=>{
        // getFotos();
    },[])

    async function getFotos() {
        try {
            await api
            .get("/expositor/logo")
            .then((res) => {
                const response = res.data;
                setFiles(response)
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.log(e.message)
        }
    }

    return(
        <>
            <Box className={[styles.background, styles.sectionOne]}>
                <Container className={styles.content}>
                    <Box container sx={{ flexGrow: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid item xs={12} className={styles.descricao}>
                                    A MAIOR FEIRA DE<br/>AGRONEGÓCIOS<br/>DA REGIÃO DE<br/>CARATINGA
                                </Grid>
                                <Grid item xs={12} className={styles.data}>
                                    <span>08, 09 e 10</span>
                                </Grid>
                                <Grid item xs={12} className={styles.mes}>
                                    <span>de agosto</span>
                                </Grid>
                                <Grid item xs={12} className={styles.hora}>
                                    <span>11h as 21h</span>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8}>
                                <Grid xs={12} className={styles.banner}>
                                    <video src={video} autoPlay muted loop/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
            <Box>
                <Container className={styles.sectionTwo}>
                    <Grid container className={styles.icons}>
                        <Grid xs={4} md={1} >
                            <img src={icon1} alt="" />
                        </Grid>
                        <Grid xs={4} md={1}>
                            <img src={icon2} alt="" />
                        </Grid>
                        <Grid xs={4} md={1}>
                            <img src={icon3} alt="" />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={styles.contador}>
                        <Clock deadline={"August, 08, 2024"} />
                    </Grid>
                    <Grid xs={12} className={styles.descricao}>
                        <h1>O melhor lugar para fazer negócios!</h1>
                        {/* <p>Proin at mi imperdiet, fermentum tellus ac, feugiat nibh. Mauris nec sapien ipsum. Nullam pellentesque lobortis tortor in condimentum. Fusce quis mauris nec lacus euismod fringilla. Proin molestie lobortis nibh, hendrerit facilisis augue hendrerit ut. Phasellus lectus nunc, varius a sapien nec, ornare lacinia tortor. Nam accumsan quam at nisi bibendum posuere. Fusce arcu mauris, tempor sed odio ac, lacinia vulputate turpis. Nullam aliquet dolor at suscipit semper.</p> */}
                    </Grid>
                    <Grid container spacing={2} className={styles.images}>
                        <Grid item xs={4} className={styles.img}>
                            <img src={img1} alt="" />
                        </Grid>
                        <Grid item xs={4} className={styles.img}>
                            <img src={img2} alt="" />
                        </Grid>
                        <Grid item xs={4} className={styles.img}>
                            <img src={img3} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/*<Box className={styles.sectionThreeBackground}>
                <Container className={styles.sectionThree}>
                    <Grid xs={12} className={styles.descricao}>
                        <h1>Conheça nossos parceiros expositores</h1>
                        <Grid container className={styles.boxLogosExpositor}>
                            {files.map((logo) => {
                                return <Grid className={styles.boxLogoExpositor} xs={6} md={2}>
                                    <Grid item
                                    className={styles.logoExpositor}
                                    style={{backgroundImage: "url("+logo.url+")"}}
                                ></Grid>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Container>
            </Box>*/}
        </>
    )
}