import { Grid, Button } from "@mui/material"
import styles from './styles.module.scss'
import { PieChart } from '@mui/x-charts/PieChart';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { useEffect, useState } from "react"
import api from "../../../../services/api";

export default function PaginaInicialExpositor() {
    const [data, setData] = useState([]);

    useEffect(()=>{
        // getDados();
    },[])

    async function getDados() {
        try {
            await api
            .post("/dashboard/expositor")
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setData(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar produtos, contate o suporte.");
                    }
                } else {
                        alert("Erro ao buscar produtos, contate o suporte.");
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }
    return (
        <Grid container>
            {/* <Grid xs={12} md={12} className={styles.acessoRapido}>
                    <Grid item xs={12} md={12} className={styles.titleMenu}>
                        Acesso rápido
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <Link to='orcamentos'>
                                <Button>
                                    <AppRegistrationOutlinedIcon/>
                                    <span>Cadastrar Orçamento</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Link to='produtos'>
                                <Button>
                                    <Inventory2OutlinedIcon/>
                                    <span>Cadastrar Produto</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Link to='funcionarios'>
                                <Button>
                                    <PersonAddAltOutlinedIcon/>
                                    <span>Cadastrar Funcionário</span>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <Button>
                                Cadastrar Orçamento
                            </Button>
                        </Grid>
                    </Grid>
            </Grid>
            <Grid xs={12} md={12} className={styles.dashboardNumbers}>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Grid container className={styles.itemDashboard}>
                            <Grid item xs={12} className={styles.itemDashboardTitle}>
                                Orçamentos cadastrados
                            </Grid>
                            <Grid item xs={12} className={styles.itemDashboardContent}>
                                {data.orcamentosTotal}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Grid container className={styles.itemDashboard}>
                            <Grid item xs={12} className={styles.itemDashboardTitle}>
                                Orçamentos aprovados
                            </Grid>
                            <Grid item xs={12} className={styles.itemDashboardContent}>
                                {data.orcamentosAprovados}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Grid container className={styles.itemDashboard}>
                            <Grid item xs={12} className={styles.itemDashboardTitle}>
                                Orçamentos em análise na agência
                            </Grid>
                            <Grid item xs={12} className={styles.itemDashboardContent}>
                                {data.orcamentosAnaliseAgencia}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Grid container className={styles.itemDashboard}>
                            <Grid item xs={12} className={styles.itemDashboardTitle}>
                                Valor total de orçamentos
                            </Grid>
                            <Grid item xs={12} className={styles.itemDashboardContent}>
                                {data.valorOrcamentosTotal}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} md={12} className={styles.dashboardCharts}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: data.valorOrcamentosTotal, label: 'Valor total', color: "#c9d200" },
                                        { id: 1, value: data.valorOrcamentosAprovados, label: 'Valor aprovado', color: "#7db61c" },
                                    ],
                                },
                            ]}
                            height={150}
                        />
                    </Grid>
                    <Grid md={2}></Grid>
                    <Grid item xs={12} md={5}>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        { id: 0, value: data.orcamentosTotal, label: 'Qtd. orcamentos', color: "#c9d200" },
                                        { id: 1, value: data.orcamentosAprovados, label: 'Qtd. aprovados', color: "#7db61c" },
                                    ],
                                },
                            ]}
                            height={150}
                        />
                    </Grid>
                </Grid>
            </Grid>*/}
        </Grid>
    )
}