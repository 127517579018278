import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel, Box } from "@mui/material"
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { NumericFormat } from 'react-number-format';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'; 

export default function PaginaOrcamentosExpositor() {
    const [filtroEtapa, setFiltroEtapa] = useState(0);
    const columns = [
        {
            name: "Observação Final",
            button: true,
            width: '100px',
            omit: filtroEtapa === 3 || filtroEtapa === 1 || filtroEtapa === 2 ? true : false,
            cell: (row) =>  row.etapa === "1" || row.etapa == 1 || row.etapa === "2" || row.etapa == 2 || row.etapa === 3 ||  row.etapa === "3"
                            ?
                            <></>
                            :
                            <span title={row.observacao_final} className={styles.obsFinal}>Obs. Final</span>
        },
        {
            name: 'Nº Orçamento',
            button: true,
            cell: (row) =>  <>
                                <span id={"id-" + row.id} title={row.observacao}>{row.id}</span>   
                            </>
        },
        { name: 'CPF/CNPJ', selector: row => row.cpf_cnpj, maxWidth: '120px' },
        { name: 'Nome', selector: row => row.nome_usuario },
        {
            name: 'Telefone',
            button: true,
            cell: (row) => <a target="blank" id={row.telefone} href={"https://wa.me/"+row.telefone}>{row.telefone}</a>   
        },
        { name: 'Agência', selector: row => row.nome_agencia },
        { name: 'Nome Gerente', selector: row => row.nome_gerente },
        { name: 'Valor', selector: row => row.valor_format },
        { name: 'Etapa', selector: row => row.etapa_descricao },
        {
            name: 'Arquivo',
            button: true,
            cell: (row) => row.name_file === "" || row.name_file === null
                            ?
                            <></>
                            :
                            <a id={row.name_file} href={location+"/api/v1/files/arquivo/"+row.name_file}>Baixar Arquivo</a>   
        },
    ];
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [nome, setNome] = useState('');
    const [produto, setProduto] = useState('');
    const [valor, setValor] = useState('');
    const [formaPag, setFormaPag] = useState('');
    const [telefone, setTelefone] = useState('');
    const [agencia, setAgencia] = useState('');
    const [observacao, setObservacao] = useState('');
    const [file, setFile] = useState("");
    const [nameFile, setNameFile] = useState("Selecione o arquivo desejado...");
    const [showCadOrcamento, setShowCadOrcamento] = useState(true);
    const [produtos, setProdutos] = useState([]);
    const [agencias, setAgencias] = useState([]);
    const [data, setData] = useState([]);
    const [qtd, setQtd] = useState(0);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [rowSelect, setRowSelect] = useState({'selectedCount': 0, 'selectedRows': []});
    const paginationComponentOptions = {
        noRowsPerPage: true,
        rangeSeparatorText: 'de'
    };
    const [location, setLocation] = useState('')

    useEffect(()=>{
        functionInitial();
        getOrcamentos();
        if(window.location.protocol == 'http') {
            setLocation(window.location.origin + ':3006');
        } else {
            setLocation(window.location.origin + ':3001');
        }
    },[])

    async function functionInitial() {
        await getProdutosAtivos();
        await getAgencias();
    }

    function handleInputCpfCnpj(e) {
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }

    function handleInputTelefone(e) {
        setTelefone(e.target.value.replace(/[^0-9]/g, ''));
    }
    
    function handleInputProduto(e) {
        setProduto(e.target.value);
    }

    async function handleInput(e) {
        const file = e.target.files[0];

        if(file) {
            if(file.size > 2097152) {
                alert("Arquivo maior que o permitido, favor enviar um arquivo com menos de 2MB");
                setFile("");
                setNameFile("Selecione o arquivo desejado...")
            } else {
                setFile(file);
                setNameFile(file.name)
            }
        } else {
            setNameFile("Selecione o arquivo desejado...")
            setFile("");
        }
        
    }

    function showCadastroOrcamento() {
        setShowCadOrcamento(!showCadOrcamento);
    }

    function limparCadastro() {
        setCpfCnpj('')
        setNome('')
        setProduto('')
        setValor('')
        setFormaPag('')
        setTelefone('')
        setAgencia('')
        setObservacao('')
        setFile('')
        setNameFile("Selecione o arquivo desejado...")
    }

    function cadastrarOrcamento() {
        var alerta = "";

        if(cpfCnpj == "" || cpfCnpj == null) {
            alerta += "CPF/CNPJ\n";
        }
        if(cpfCnpj.length < 11) {
            alerta += "Informe um CPF/CNPJ completo\n";
        }
        if(cpfCnpj.length > 11 && cpfCnpj.length < 14) {
            alerta += "Informe um CPF/CNPJ completo\n"
        }
        if(!cpfValidator.isValid(cpfCnpj) && !cnpjValidator.isValid(cpfCnpj)) {
            alerta += 'CPF/CNPJ inválido\n';
        }
        if(nome == "" || nome == null) {
            alerta += "Nome\n";
        }
        if(produto == "" || produto == null) {
            alerta += "Produto\n";
        }
        if(valor == "" || valor == null) {
            alerta += "Valor\n";
        }
        if(formaPag == "" || formaPag == null) {
            alerta += "Forma Pagamento\n";
        }
        if(telefone == "" || telefone == null) {
            alerta += "Telefone\n";
        }
        if(agencia == "" || agencia == null) {
            alerta += "Agencia\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
            criarOrcamento();
        {/*if(file === "" || file === null) {
        } else {
            criarOrcamentoFile();
        }*/}
    }

    async function criarOrcamentoFile() {
        const agenciaSelect = agencias.find((agen) => {
            return agen.id === agencia;
        });
        const cidade = agenciaSelect.ibge;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('cpf_cnpj', cpfCnpj);
        formData.append('nome', nome);
        formData.append('valor', valor);
        formData.append('observacao', observacao);
        formData.append('forma_pagamento', formaPag);
        formData.append('id_produto', produto);
        formData.append('telefone', telefone);
        formData.append('agencia', agencia);
        formData.append('cidade', cidade);
        formData.append('user_type', 1);
        
        try {
            await api
            .post("/orcamentos/create-arquivo", formData, { 
                headers: {
                    "Content-Type": `multipart/form-data`
                }
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    if(response.erro === 0) {
                        alert("Orçamento cadastrado com sucesso.")
                        limparCadastro()
                        getOrcamentosFiltro(filtroEtapa)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                }
                
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }

    async function criarOrcamento() {
        const agenciaSelect = agencias.find((agen) => {
            return agen.id === agencia;
        });
        const cidade = agenciaSelect.ibge;
        
        try {
            await api
            .post("/orcamentos/create", {
                cpf_cnpj: cpfCnpj,
                valor: valor,
                observacao: observacao,
                forma_pagamento: formaPag,
                id_produto: produto,
                telefone: telefone,
                nome: nome,
                agencia: agencia,
                cidade: cidade,
                user_type: 1,
                id_usuario: getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        alert("Orçamento cadastrado com sucesso.")
                        limparCadastro()
                        getOrcamentosFiltro(filtroEtapa)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }

    async function getAgencias() {
        try {
            await api
            .get("/agencia")
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setAgencias(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
    }

    async function getProdutosAtivos() {
        try {
            await api
            .post("/produtos/expositor", {
                'situacao': 1,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setProdutos(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
    }

    function handleRowSelect (e) {
        setRowSelect(e)
    }

    function filterPerdente() {
        setFiltroEtapa(6)
        getOrcamentosFiltro(6)
    }

    function filterAprovado() {
        setFiltroEtapa(4)
        getOrcamentosFiltro(4)
    }

    function filterReprovado() {
        setFiltroEtapa(5)
        getOrcamentosFiltro(5)
    }

    function filterAnaliseAgencia() {
        setFiltroEtapa(2)
        getOrcamentosFiltro(2)
    }

    function semFiltro() {
        setFiltroEtapa(0)
        getOrcamentosFiltro()
    }

    function encaminhaOrcamentoAgencia() {
        if(rowSelect.selectedCount > 0) {
            atualizaEtapaOrcamento(2)
        }
    }

    function recusaOrcamento() {
        if(rowSelect.selectedCount > 0) {
            atualizaEtapaOrcamento(8)
        }
    }

    function getIdRowSelect() {
        var ids = []
        rowSelect.selectedRows.forEach(element => {
            ids.push(element.id)
        });
        return ids;
    }

    async function getOrcamentosFiltro(etapa) {
        setPending(true)
        setPage(1)
        try {
            await api
            .post("/orcamentos/expositor", {
                'offset': 0,
                'limit': 10,
                'etapa': etapa,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                        setQtd(response.qtd)
                        setData(response.data)
                        // props.atualizado()
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                    }
                } else {
                    alert("Erro ao buscar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function handlePageChange(e) {
        var newPage = 0;
        if(e.target.id === "back") {
            newPage = page - 1;
            await setPage(page - 1);
        } else {
            newPage = page + 1;
            await setPage(page + 1);
        }

        setPending(true)

        const offset = (newPage * 10) - 10;
        
        try {
            await api
            .post("/orcamentos/expositor", {
                'offset': offset,
                'limit': 10,
                'etapa': filtroEtapa,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                        setQtd(response.qtd)
                        setData(response.data)
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                    }
                } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    };

    async function getOrcamentos() {
        setPending(true)
        setPage(1)
        const offset = ((page) * 10) - 10;
        
        try {
            await api
            .post("/orcamentos/expositor", {
                'offset': offset,
                'limit': 10,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setQtd(response.qtd)
                        setData(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                    }
                } else {
                    alert("Erro ao buscar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function atualizaEtapaOrcamento(etapa) {
        setPending(true)
        const id = getIdRowSelect();
        
        try {
            await api
            .put("/orcamentos/expositor", {
                'id': id,
                'etapa': etapa,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                } else {
                    alert("Erro ao atualizar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    return (
        <Grid container>
            <Grid xs={12} className={[styles.cadastroOrcamento , !showCadOrcamento ? styles.cadastroOrcamentoHidden : '']}>
                <Grid container className={styles.cadastroOrcamentoTopBar} onClick={showCadastroOrcamento}>
                    <Grid xs={10} className={styles.cadastroOrcamentoTitle}>
                        <h2>Cadastrar negócio/orçamento</h2>
                    </Grid>
                    <Grid xs={2} className={[styles.cadastroOrcamentoIcon]}>
                        <span><KeyboardArrowDownIcon/></span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={[styles.cadastroOrcamentoSection]}>
                    <Grid item xs={12} md={4}>
                        <InputMask
                            mask={cpfCnpj.length < 12 ? "999.999.999-999" : "99.999.999/9999-99"}
                            maskChar=""
                            value={cpfCnpj}
                            onChange={handleInputCpfCnpj}
                        >
                            <TextField fullWidth  label="CPF/CNPJ" variant="outlined"/>
                        </InputMask>
                    </Grid>
                    {/* <Grid item xs={6} md={2}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonValidar} margin="dense">VALIDAR</Button>
                    </Grid> */}
                    <Grid item xs={12} md={8}>
                        <TextField fullWidth  label="Nome / Razão Social" variant="outlined" value={nome} onChange={(event) => {setNome(event.target.value)}}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Produto</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={produto}
                                    label="Produto"
                                    onChange={handleInputProduto}
                                    disabled={produtos.length <= 0}
                                    >
                                    {produtos.map((produto) => {
                                        return <MenuItem key={produto.id} value={produto.id}>{produto.nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField fullWidth  label="Valor Orçamento" value={valor} onChange={(event) => {setValor(event.target.value.replace(/[^0-9]/g, ''))}} type="number" variant="outlined" className={styles.textFielPadTop}/>
                        
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Forma de Pagamento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formaPag}
                                    label="Forma de Pagamento"
                                    onChange={(event) => {setFormaPag(event.target.value)}}
                                >
                                    <MenuItem value={1}>Financiamento Credcooper</MenuItem>
                                    <MenuItem value={2}>Direto com o Expositor</MenuItem>
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMask
                            mask={"(99) 9 9999-9999"}
                            maskChar=""
                            value={telefone}
                            onChange={handleInputTelefone}
                        >
                            <TextField fullWidth  label="Telefone" variant="outlined" className={styles.textFielPadTop}/>
                        </InputMask>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Agência</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={agencia}
                                    label="Agência"
                                    onChange={(event) => {setAgencia(event.target.value)}}
                                >
                                    {agencias.map((agencia) => {
                                        return <MenuItem key={agencia.id} value={agencia.id}>{agencia.nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth  label="Observação" variant="outlined" className={styles.textFielPadTop} value={observacao} onChange={(event) => {setObservacao(event.target.value)}}/>
                    </Grid>
                    {/*<Grid item xs={12} md={3} className={styles.sectionFile}>
                        <span style={{fontSize: "0.8rem"}}>
                            Anexar arquivo:
                        </span>
                        <label htmlFor="foto-produto" className={styles.labelFile}>
                            {nameFile}
                        </label>
                        <input type="file" id="foto-produto" name="" className={styles.inputFile} onChange={handleInput}/>                     
                    </Grid>*/}
                    <Grid item xs={12} md={7}>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} onClick={cadastrarOrcamento} margin="dense">SALVAR</Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonLimpar} onClick={limparCadastro} margin="dense">LIMPAR</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} className={styles.orcamentosTable}>
                <Box>
                    <Grid container className={styles.buttonsTable} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h3>Filtros</h3>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAnaliseAgencia} disabled={filtroEtapa === 2}>Análise agência</Button>
                        </Grid>                  
                        <Grid item xs={6} md={3}>
                            <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAprovado} disabled={filtroEtapa === 4}>Aprovado</Button>
                        </Grid>                  
                        <Grid item xs={6} md={3}>
                            <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterReprovado} disabled={filtroEtapa === 5}>Cooperado Comunicado</Button>
                        </Grid>                  
                        <Grid item xs={6} md={3}>
                            <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={semFiltro} disabled={filtroEtapa === 0}>Todos</Button>
                        </Grid>                  
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={5}>
                        <h3>Botões de ação (selecione uma linha para ativar)</h3>
                        <Grid container spacing={2}>
                        <Grid item xs={7} md={7}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonEncaminharOrc} onClick={encaminhaOrcamentoAgencia} disabled={rowSelect.selectedCount === 0}>Encaminhar para agência</Button>
                        </Grid>                  
                        <Grid item xs={5} md={5}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonRecusarOrc} onClick={recusaOrcamento} disabled={rowSelect.selectedCount === 0}>Recusar</Button>
                        </Grid>                  
                        </Grid>
                    </Grid> */}
                    </Grid>
                    <Grid className={styles.observacoes}>
                        <span>*Passe o mouse sobre "Nº Orçamento" para ver a descrição informada pelo expositor</span><br/>
                        { filtroEtapa !== 1 && filtroEtapa !== 2 && filtroEtapa !== 3 ? <span>**Passe o mouse sobre "Ob. Final" para ver o parecer final da agência</span> : <></>}
                    </Grid>
                    <DataTable
                        columns={columns}
                        data={data}
                        // selectableRows
                        onSelectedRowsChange={handleRowSelect}
                        selectableRowDisabled={row => row.etapa !== 6}
                        progressPending={pending}
                    />
                    {
                        pending ?
                        <></>
                        :
                        <Grid container className={styles.paginationTable} justifyContent="center">
                            <Grid xs={1}>
                                {
                                ((page * 10) - 10) === 0 ?
                                <KeyboardArrowLeftIcon className={styles.arrowLeftDisabled} id="back"/>
                                :
                                <KeyboardArrowLeftIcon className={styles.arrowLeft} id="back" onClick={handlePageChange}/>
                                }
                            </Grid>
                            <Grid xs={3} md={2} className={styles.numberPagination}>
                                {(page * 10) - 10} a {(page * 10) > qtd ? qtd : (page * 10)} de {qtd}
                            </Grid>
                            <Grid xs={1}>
                                {
                                (page * 10) >= qtd ?
                                <KeyboardArrowRightIcon className={styles.arrowRightDisabled} id="next"/>
                                :
                                <KeyboardArrowRightIcon className={styles.arrowRight} id="next" onClick={handlePageChange}/>
                                }
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}