import { InputLabel, MenuItem, FormControl, Box, Grid, TextField, Button } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import doodle from '../../assets/pessoas_cumprimentando.webp'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../services/api_credenciamento";
import JsBarcode from 'jsbarcode';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export default function Cadastro() {
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [nome, setNome] = useState("");
    const [agencia, setAgencia] = useState("");
    const [agencias, setAgencias] = useState([]);
    const [disableCadastro, setDisableCadastro] = useState(true);

    useEffect(()=>{
        functionInitial();
    },[])

    async function functionInitial() {
        await getAgencias();
    }

    function handleInputCpfCnpj(e) {
        limparCadastro()
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }

    function limparCadastro() {
        setNome("");
        setAgencia("");
        setDisableCadastro(true);
    }

    async function getAgencias() {
        try {
            await api
            .get("/agencia")
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setAgencias(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.log(e.message)
        }
    }

    async function PrintJS(cpf_cnpj, nome, agencia, tipo_usuario) {
        
        if (typeof window !== "undefined") {
            // Acesso(cpf_cnpj); //REGISTRAR O ACESSO DO VISITANTE
            const printJS = require('print-js');
    
            const someJSONdata = [
                {
                    // pa: agencia,
                },
            ]
    
            var nome_split = nome.split(" ");
            var nome_impressao = nome;
            if(nome_split.length >= 2) {
                nome_impressao = nome_split[0]+" "+nome_split[nome_split.length-1];
            }
    
    
            var associado_impressao = tipo_usuario;
    
            const { DOMImplementation, XMLSerializer } = require('xmldom');
            const xmlSerializer = new XMLSerializer();
            const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
            const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    
            JsBarcode(svgNode, cpf_cnpj, {
                xmlDocument: document,
                displayValue: false,
                height: 15,
                textMargin: 0,
                marginTop: 0,
                background: 'transparent',
            });
    
            const svgText = xmlSerializer.serializeToString(svgNode);
    
            const descricao = agencia !== "" ?
                    '<div style="margin-top: -10px">\
                    <p style="margin: 0; padding: 0;font-family: sans-serif; font-size: 10px; line-height: 0;">'+agencia+'</p></br>\
                    <p style="margin: 0; padding: 0;font-style: italic;font-family: sans-serif; font-size: 10px; line-height: 0;">'+associado_impressao+'</p>\
                    </div>' : 
                    '<div style="margin-top: -10px">\
                    <p style="margin: 0; padding: 0;font-style: italic;font-family: sans-serif; font-size: 10px; line-height: 0;">'+associado_impressao+'</p>\
                    </div>'
            
            await printJS({
                printable: someJSONdata,
                properties: [],
                header: '<div style="z-index: 999999; left: 0; margim: 0; text-align: center; width: 100%; background-color: transparente !important;">\
                            <div style="top: 0">'+svgText+'</div>\
                            <p style="font-weight: bold;margin-top: 0px; padding: 0;font-family: sans-serif; text-transform: uppercase; font-size: 15px; line-height: 0; text-align: center" margin:auto;>'
                                +nome_impressao+
                            '</p></br>'
                            +descricao+
                        '</div>',
                type: 'json'})
                
                setCpfCnpj("")
        }
    }
    
    async function registrarCracha() {
        api
        .post("/registrar-cracha", {
            cpf_cnpj: cpfCnpj
        })
        .then((res) => {
            if(res.data) {
                const response = res.data;
                
                if(response.erro === 0) {
        
                } else {
                    console.error("Erro ao emitir cracha, tente novamente")
                }
            }
    
            
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
     }
    
    async function VerifyUser() {
        limparCadastro();

        if(cpfCnpj === '') {
            alert("Informe um CPF ou CNPJ");
            return;
        }
        if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
            alert("Informe um CPF ou CNPJ completo");
            return;
        }
        if(!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
            alert('CPF/CNPJ inválido');
            return;
        }
        
        try {
        
            api
            .post("/verifyUser", {
                cpf_cnpj: cpfCnpj
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.qtd > 0) {
                        var nome = response.data[0].nome;
                        var cpf_cnpj = response.data[0].cpf_cnpj;
                        var agencia = response.data[0].cpf_gerente != null ? response.data[0].nome_agencia : response.data[0].cidade;
                        // setUserRegistered("true");
                        var type_user = ""; 
                        response.data[0].types_user.map((user_type) => {
                            if(user_type.user_type === 3 || user_type.user_type === 5) {
                                type_user = "SICOOB CREDCOOPER";
                                agencia = "";
                            } else if ((user_type.user_type === 2 || user_type.user_type === 4) && type_user !== "SICOOB CREDCOOPER") {
                                type_user = "EXPOSITOR";
                                agencia = "";
                            }
                        });
                        var tipo_usuario = type_user !== "" ? type_user : response.data[0].cpf_gerente !== null ? "ASSOCIADO" : "";
                        registrarCracha()
                        PrintJS(cpf_cnpj, nome, agencia, tipo_usuario);
                        setDisableCadastro(true)
                    } else {
                        setDisableCadastro(false)
                    }
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.log(e.message)
        }
    }

    async function createUser() {
        var alerta = "";
        if(cpf == "" || cpf == null) {
            alerta += "CPF\n";
        }
        if(nome == "" || nome == null) {
            alerta += "Nome\n";
        }
        if(agencia == "" || agencia == null) {
            alerta += "Agencia\n";
        }
        if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
            alerta += "Informe um CPF ou CNPJ completo";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
        if(!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
            alert('CPF/CNPJ inválido');
            return;
        }

        const agenciaSelect = await agencias.find((agen) => {
            return agen.id === agencia;
        });
        const cidade = agenciaSelect.ibge;
        const nomeAgencia = agenciaSelect.nome;
        
        try {
        
            api
            .post("/credenciamento", {
                cpf_cnpj: cpfCnpj,
                nome: nome,
                agencia: agencia,
                cidade: cidade
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        registrarCracha()
                        PrintJS(cpfCnpj, nome, nomeAgencia, "");
                        limparCadastro();
                    } else {
                        console.error("Ocorreu um erro ao cadastrar, tente novamente.");
                        setDisableCadastro(false)
                    }
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.log(e.message)
        }
    }

    return (
        <Box className={styles.background}>
            <Grid md={12} className={styles.doddle}>
                <img src={doodle} alt="" />
            </Grid>
            <Grid container justifyContent="center">
                <Grid xs={10} md={6}>
                    <Grid container className={styles.boxCadastro}>
                        <Grid xs={12} md={12} className={styles.formCadastro}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <h1 className={styles.titleCadastro}>Credenciamento FENASC</h1>
                                </Grid>
                                <Grid item xs={7} md={7}>
                                    <TextField fullWidth  label="CPF/CNPJ" variant="outlined" margin="dense" value={cpfCnpj} onChange={handleInputCpfCnpj}/>
                                </Grid>
                                <Grid item xs={5} md={5}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonValidar} margin="dense" onClick={VerifyUser}>VALIDAR</Button>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth  label="Nome/Razão Social" variant="outlined" margin="dense" value={nome} onChange={(e) => setNome(e.target.value)} disabled={disableCadastro}/>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <DemoContainer components={["Select"]}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Agência</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={agencia}
                                                label="Agência"
                                                onChange={(event) => {setAgencia(event.target.value)}}
                                                disabled={disableCadastro}
                                            >
                                                {agencias.map((agencia) => {
                                                    return <MenuItem key={agencia.id} value={agencia.id}>{agencia.nome}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </DemoContainer>
                                </Grid>
                                <Grid item xs={12} md={12} className={styles.divButtonCadastro}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonCadastro} margin="dense" disabled={disableCadastro} onClick={createUser}>CADASTRAR</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}