import React, { useEffect, useState } from "react";
import { Box, Container, Grid } from "@mui/material"
import styles from './styles.module.scss'

const Clock = ({ deadline }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    }
  };

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000);

    return () => getTimeUntil(deadline);
  }, [deadline]);

  return (
    <Box>
        <Grid container spacing={2} className={styles.counter}>
            <Grid item xs={6} md={3} className={styles.value}>
                <span><b>{leading0(days)}</b> Dias</span>
            </Grid>
            <Grid item xs={6} md={3} className={styles.value}>
                <span><b>{leading0(hours)}</b> Horas</span>
            </Grid>
            <Grid item xs={6} md={3} className={styles.value}>
                <span><b>{leading0(minutes)}</b> Minutos</span>
            </Grid>
            <Grid item xs={6} md={3} className={styles.value}>
                <span><b>{leading0(seconds)}</b> Segundos</span>
            </Grid>
        </Grid>
    </Box>
  );
};

export default Clock;
