import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel, Box } from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { cpf as cpfValidator } from 'cpf-cnpj-validator'; 

export default function PaginaFuncionarios() {
    const columns = [
        { name: 'ID', selector: row => row.id, maxWidth: '50px' },
        { name: 'CPF', selector: row => row.cpf, maxWidth: '200px' },
        { name: 'Nome', selector: row => row.nome },
        { name: 'Agência', selector: row => row.nome_agencia },
        { name: 'Situacao', cell: (row) => <>{row.situacao === 1 ? 'Ativo' : 'Inativo'}</>
        },
        {
            name: 'Senha',
            button: true,
            width: '150px',
            cell: (row) => <Button 
                            className={styles.btnSenha}
                            value={row.id} onClick={handleClickOpen}>Alterar Senha</Button>,
        },
        {
            name: 'Ação',
            button: true,
            cell: (row) => <Button 
                            className={[row.situacao === 1 ? styles.btnSituacaoInativar : styles.btnSituacaoAtivar]}
                            value={row.id} onClick={handleChangeSituacao}>
                                {row.situacao === 1 ? 'Inativar' : 'Ativar'}
                            </Button>,
        },
    ];
    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");
    const [senha, setSenha] = useState("");
    const [showCadFuncionario, setShowCadFuncionario] = useState(true);
    const [data, setData] = useState([]);
    const [qtd, setQtd] = useState(0);
    const [tipoUsuario, setTipoUsuario] = useState('5');
    const [agencia, setAgencia] = useState("");
    const [agencias, setAgencias] = useState([]);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [rowSelect, setRowSelect] = useState({'selectedCount': 0, 'selectedRows': []});
    const [filtroEtapa, setFiltroEtapa] = useState("");
    const [changePassword, setChangePassword] = useState('')
    const [modal, setModal] = useState(false)
    const [idModal, setIdModal] = useState('')

    useEffect(()=>{
        if(getCookie('userType') === '5') {
            window.location.href = "/painel-colaborador";
        }
        functionInitial();
    },[])

    async function functionInitial() {
        await getUsuarios("");
        await getAgencias();
    }

    function handleInputCpf(e) {
        setCpf(e.target.value.replace(/[^0-9]/g, ''));
    }

    async function handleChangeSituacao(e) {
        const usuarioSelect = await data.find((usuario) => {
            return usuario.id == e.target.value;
        });
        updateUsuario(usuarioSelect.id, !usuarioSelect.situacao)
    }

    function showCadastroFuncionario() {
        setShowCadFuncionario(!showCadFuncionario);
    }

    function filterAtivos() {
        setFiltroEtapa(1)
        getUsuarios(1)
    }

    function filterInativos() {
        setFiltroEtapa(0)
        getUsuarios('0')
    }

    function semFiltro() {
        setFiltroEtapa("")
        getUsuarios("")
    }

    function handleRowSelect (e) {
        setRowSelect(e)
    }

    function handleClickOpen(e) {
        setIdModal(e.target.value);
        setModal(true);
    };
    
    function handleClose() {
        setIdModal('');
        setChangePassword('');
        setModal(false);
    };

    function handleChangePassword(e) {
        setChangePassword(e.target.value);
    }

    function handleCpf(e) {
        setCpf(e.target.value.replace(/[^0-9]/g, ''));
    }

    function saveChangePassword(e) {
        if(changePassword == "" || changePassword == null) {
            alert("Favor informar a nova senha");
            return
        } else {
            if(updateUsuario(idModal)) {
                alert('Atualização realizada com sucesso!')
            }
        }
    }

    function limparCadastro() {
        setCpf("");
        setNome("");
        setSenha("");
        setAgencia("");
        setTipoUsuario("5");
    }

    async function getAgencias() {
        await api
        .get("/agencia")
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                setAgencias(response.data)
            } else if(response.erro !== 0) {
                alert("Erro ao atualizar orçamentos, contate o suporte.");
            } else {
                alert("Erro ao atualizar orçamentos, contate o suporte.")
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
            alert("Sessão expirada, gentileza realizar o login novamente")
            deleteCookie('token');
            deleteCookie('exp');
            deleteCookie('userType');
            window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function updateUsuario(id, novaSituacao) {
        await api
        .put("/usuario-funcionario", {
            'id': id,
            'situacao': novaSituacao,
            'senha': changePassword
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                getUsuarios(filtroEtapa)
                handleClose()
                return true;
            } else if(response.erro !== 0) {
                alert("Erro ao atualizar usuario, contate o suporte.");
                return false;
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                deleteCookie('token');
                deleteCookie('exp');
                deleteCookie('userType');
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
        setPending(false)
    }

    async function createUsuario() {
        var alerta = "";
        if(cpf == "" || cpf == null) {
            alerta += "CPF\n";
        }
        if(nome == "" || nome == null) {
            alerta += "Nome\n";
        }
        if(senha == "" || senha == null) {
            alerta += "Senha\n";
        }
        if(agencia == "" || agencia == null) {
            alerta += "Agência\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
        if(cpf.length < 11) {
            alert("Informe um CPF completo");
            return;
        }
        if(!cpfValidator.isValid(cpf)) {
            alert('CPF inválido');
            return;
        }
        await api
        .post("/usuario-funcionario/cadastro", {
            'cpf': cpf,
            'nome': nome,
            'senha': senha,
            'agencia': agencia,
            'user_type': tipoUsuario
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                alert('Cadastro realizado com sucesso!')
                getUsuarios(filtroEtapa)
                limparCadastro()
            } else if(response.erro !== 0) {
                alert(response.mensagem);
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                deleteCookie('token');
                deleteCookie('exp');
                deleteCookie('userType');
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
        setPending(false)
    }

    async function getUsuarios(situacaoUsuario) {
        setPending(true)
        setPage(1)
        await api
        .post("/usuario-funcionario", {
            'situacao': situacaoUsuario
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                setQtd(response.qtd)
                setData(response.data)
            } else if(response.erro !== 0) {
                alert("Erro ao buscar orçamentos, contate o suporte.");
            }
        })
        .catch((err) => {
            if(!err.response.data.auth) {
                alert("Sessão expirada, gentileza realizar o login novamente")
                deleteCookie('token');
                deleteCookie('exp');
                deleteCookie('userType');
                window.location.href = "/login";
            }
            console.error("ops! ocorreu um erro" + err);
        });
        setPending(false)
    }

    return (
        <Grid container>
            <Grid xs={12} className={[styles.cadastroFuncionario , !showCadFuncionario ? styles.cadastroFuncionarioHidden : '']}>
                <Grid container className={styles.cadastroFuncionarioTopBar} onClick={showCadastroFuncionario}>
                    <Grid xs={10} className={styles.cadastroFuncionarioTitle}>
                        <h2>Cadastrar funcionário</h2>
                    </Grid>
                    <Grid xs={2} className={[styles.cadastroFuncionarioIcon]}>
                        <span><KeyboardArrowDownIcon/></span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={[styles.cadastroFuncionarioSection]}>
                    <Grid item xs={12} md={4}>
                        <InputMask
                            mask={"999.999.999-99"}
                            maskChar=""
                            value={cpf}
                            onChange={handleCpf}
                        >
                            <TextField fullWidth label="CPF" variant="outlined"/>
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <form noValidate autoComplete="off">
                            <TextField id="nomeFuncionario" fullWidth value={nome} onChange={(event) => {setNome(event.target.value)}} label="Nome / Razão Social" autoComplete='off' variant="outlined"/>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <form noValidate autoComplete="off">
                            <TextField className={styles.textFielPadTop} fullWidth value={senha} onChange={(event) => {setSenha(event.target.value)}} label="Senha" type="password" autoComplete='off' variant="outlined"/>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Agência</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={agencia}
                                    label="Agência"
                                    onChange={(event) => {setAgencia(event.target.value)}}
                                >
                                    {agencias.map((agencia) => {
                                        return <MenuItem key={agencia.id} value={agencia.id}>{agencia.nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Acesso</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipoUsuario}
                                    label="Tipo usuário"
                                    onChange={(event) => {setTipoUsuario(event.target.value)}}
                                >
                                    <MenuItem key={"3"} value={"5"}>Padrão</MenuItem>
                                    <MenuItem key={"5"} value={"3"}>Master</MenuItem>
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid container spacing={2} className={[styles.cadastroFuncionarioButtons]} justifyContent={'flex-end'} alignItems={'flex-end'}>
                        <Grid item xs={12} md={3} mdOffset={7}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} onClick={createUsuario} margin="dense">SALVAR</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonLimpar} onClick={limparCadastro} margin="dense">LIMPAR</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} className={styles.FuncionariosTable}>
                <Box>
                    <Grid container className={styles.buttonsTable} spacing={2}>
                    <Grid item xs={12} md={12}>
                        <h3>Filtros</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAtivos} disabled={filtroEtapa === 1}>Ativos</Button>
                            </Grid>                                  
                            <Grid item xs={6} md={3}>
                                <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterInativos} disabled={filtroEtapa === 0}>Inativos</Button>
                            </Grid>                  
                            <Grid item xs={6} md={3}>
                                <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={semFiltro} disabled={filtroEtapa === ""}>Todos</Button>
                            </Grid>                  
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} md={5}>
                        <h3>Botões de ação (selecione uma linha para ativar)</h3>
                        <Grid container spacing={2}>
                            <Grid item xs={7} md={4}>
                                <Button fullWidth variant="contained" size="large" className={styles.buttonEncaminharOrc}  disabled={rowSelect.selectedCount === 0}>Inativar</Button>
                            </Grid>                  
                            <Grid item xs={5} md={4}>
                                <Button fullWidth variant="contained" size="large" className={styles.buttonRecusarOrc}  disabled={rowSelect.selectedCount === 0}>Ativar</Button>
                            </Grid>                  
                        </Grid>
                    </Grid> */}
                    </Grid>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                    {/* <Grid container className={styles.paginationTable} justifyContent="center">
                        <Grid xs={1}>
                            {
                            ((page * 10) - 10) == 0 ?
                            <KeyboardArrowLeftIcon className={styles.arrowLeftDisabled} id="back"/>
                            :
                            <KeyboardArrowLeftIcon className={styles.arrowLeft} id="back" onClick={handlePageChange}/>
                            }
                        </Grid>
                        <Grid xs={3} md={2} className={styles.numberPagination}>
                            {(page * 10) - 10} a {(page * 10) > qtd ? qtd : (page * 10)} de {qtd}
                        </Grid>
                        <Grid xs={1}>
                            {
                            (page * 10) >= qtd ?
                            <KeyboardArrowRightIcon className={styles.arrowRightDisabled} id="next"/>
                            :
                            <KeyboardArrowRightIcon className={styles.arrowRight} id="next" onClick={handlePageChange}/>
                            }
                        </Grid>
                    </Grid> */}
                </Box>
            </Grid>
            <Dialog 
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modal}
                fullWidth
                maxWidth="80%"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Nova Senha
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Grid>
                        <form noValidate autoComplete="off">
                            <TextField fullWidth label="Senha" value={changePassword} onChange={handleChangePassword} type="password" variant="outlined"/>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button value={idModal} autoFocus onClick={saveChangePassword}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}