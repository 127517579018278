import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel,Box } from "@mui/material"
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export default function PaginaProdutosExpositor() {
    const columns = [
        { name: 'ID', selector: row => row.id, maxWidth: '50px' },
        { name: 'Nome', selector: row => row.nome },
        { name: 'Situacao', cell: (row) => <>{row.is_active === 1 ? 'Ativo' : 'Inativo'}</>
        },
        { name: 'Valor', selector: row => row.valor },
        {
            name: 'Imagem',
            grow: 0,
            cell: row => <img height="50" width="50px" alt={row.name} src={isBase64(row.picture) || row.picture === '' || row.picture === '{}' ? row.picture : row.file} />,
        },
        {
            name: 'Ação',
            button: true,
            cell: (row) => <Button 
                            className={[row.is_active === 1 ? styles.btnSituacaoInativar : styles.btnSituacaoAtivar]}
                            value={row.id} onClick={handleChangeSituacao}>
                                {row.is_active === 1 ? 'Inativar' : 'Ativar'}
                            </Button>,
        },
    ];
    const [showCadProduto, setShowCadProduto] = useState(true);
    const [fileBase64, setFileBase64]= useState("");
    const [file, setFile]= useState("");
    const [nome, setNome]= useState("");
    const [valor, setValor]= useState("");
    const [descricao, setDescricao]= useState("");
    const [data, setData] = useState([]);
    const [qtd, setQtd] = useState(0);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [rowSelect, setRowSelect] = useState({'selectedCount': 0, 'selectedRows': []});
    const [filtroEtapa, setFiltroEtapa] = useState("");

    useEffect(()=>{
        functionInitial();
    },[])

    async function functionInitial() {
        getProdutos()
    }

    function isBase64(str) {
        if(str.search('base64') < 0) {
            return false;
        } else {
            return true;
        }
    }

    function filterAtivos() {
        setFiltroEtapa(1)
        getProdutos(1)
    }

    function filterInativos() {
        setFiltroEtapa(0)
        getProdutos('0')
    }

    function semFiltro() {
        setFiltroEtapa("")
        getProdutos("")
    }

    function showCadastroProduto() {
        setShowCadProduto(!showCadProduto);
    }

    async function handleChangeSituacao(e) {
        const produtoSelect = await data.find((produto) => {
            return produto.id == e.target.value;
        });
        updateProdutos(produtoSelect.id, !produtoSelect.is_active)
    }

    function limparCadastro() {
        setNome("");
        setValor("");
        setDescricao("");
        setFile("");
        setFileBase64("");
    }

    async function handleInputPicture(e) {
        const file = e.target.files[0];

        if(file) {
            if (file.type.search('image') < 0) {
                alert("Arquivo inválido, permitido somente imagens");
            } else if(file.size > 2097152) {
                alert("Arquivo maior que o permitido, favor enviar um arquivo com menos de 2MB");
            } else {
                var reader = new FileReader();
                var fileBase64 = null;
                reader.readAsDataURL(file);
                reader.onload = async function () {
                    fileBase64 = reader.result;
                    setFileBase64(fileBase64);
                    setFile(file);
                }
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    setFileBase64("");
                };
            }
        } else {
            setFileBase64("");
        }
        
    }

    async function handlePageChange(e) {
        var newPage = 0;
        if(e.target.id === "back") {
            newPage = page - 1;
            await setPage(page - 1);
        } else {
            newPage = page + 1;
            await setPage(page + 1);
        }

        setPending(true)

        const offset = (newPage * 10) - 10;
        
        try {
            await api
            .post("/produtos/expositor/paginate", {
                'offset': offset,
                'limit': 10,
                'situacao': filtroEtapa,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId'),
            })
            .then((res) => {
                const response = res.data;
                
                if(response.erro === 0) {
    
                    setQtd(response.qtd)
                    setData(response.data)
    
                } else if(response.erro !== 0) {
                    alert("Erro ao buscar produtos, contate o suporte.");
                }
            })
            .catch((err) => {
                if(!err.response.data.auth) {
                    alert("Sessão expirada, gentileza realizar o login novamente")
                    deleteCookie('token');
                    deleteCookie('exp');
                    deleteCookie('userType');
                    window.location.href = "/login";
                }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    };

    async function criarProdutoOld() {
        var alerta = "";
        if(nome == "" || nome == null) {
            alerta += "Nome\n";
        }
        if(valor == "" || valor == null) {
            alerta += "Valor\n";
        }
        if(descricao == "" || descricao == null) {
            alerta += "Descrição\n";
        }
        if(file == "" || file == null) {
            alerta += "Foto\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('nome', nome);
        formData.append('valor', valor);
        formData.append('descricao', descricao);
        formData.append('user_type', getCookie('userType'));
        formData.append('id_usuario', getCookie('userId'));
        
        try {
            await api
            .post("/produtos/expositor/cadastro", formData, { 
                headers: {
                    "Content-Type": `multipart/form-data`
                }
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        alert('Produto cadastrado com sucesso!')
                        getProdutos(filtroEtapa)
                        limparCadastro()
                    } else if(response.erro !== 0) {
                        alert(response.mensagem);
                    }
                } else {
                    alert("Ocorreu um erro, contate o suporte");
                    
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }
    
     async function criarProduto() {
        var alerta = "";
        if(nome == "" || nome == null) {
            alerta += "Nome\n";
        }
        if(valor == "" || valor == null) {
            alerta += "Valor\n";
        }
        if(descricao == "" || descricao == null) {
            alerta += "Descrição\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
        
        try {
            await api
            .post("/produtos/expositor/create", { 
                nome: nome,
                valor: valor,
                descricao: descricao,
                user_type: getCookie('userType'),
                id_usuario: getCookie('userId')
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        alert('Produto cadastrado com sucesso!')
                        getProdutos(filtroEtapa)
                        limparCadastro()
                    } else if(response.erro !== 0) {
                        alert(response.mensagem);
                    }
                } else {
                    alert("Ocorreu um erro, contate o suporte");
                    
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }

    async function getProdutos(filtro) {
        setPending(true)
        setPage(1);
        try {
            await api
            .post("/produtos/expositor/paginate", {
                'offset': 0,
                'limit': 10,
                'situacao': filtro == undefined ? filtroEtapa : filtro,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId'),
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                        setQtd(response.qtd)
                        setData(response.data)
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar produtos, contate o suporte.");
                    }
                } else {
                        alert("Erro ao buscar produtos, contate o suporte.");
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function updateProdutos(id, novaSituacao) {
        try {
            await api
            .put("/produtos", {
                'id': id,
                'situacao': novaSituacao,
                'user_type': getCookie('userType'),
                'id_usuario': getCookie('userId'),
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        getProdutos(filtroEtapa)
                        return true;
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar usuario, contate o suporte.");
                        return false;
                    }
                } else {
                        alert("Erro ao atualizar usuario, contate o suporte.");
                    
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    return (
        <Grid container>
            <Grid xs={12} className={[styles.cadastroProduto , !showCadProduto ? styles.cadastroProdutoHidden : '']}>
                <Grid container className={styles.cadastroProdutoTopBar} onClick={showCadastroProduto}>
                    <Grid xs={10} className={styles.cadastroProdutoTitle}>
                        <h2>Cadastrar produto</h2>
                    </Grid>
                    <Grid xs={2} className={[styles.cadastroProdutoIcon]}>
                        <span><KeyboardArrowDownIcon/></span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={[styles.cadastroProdutoSection]}>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TextField fullWidth  label="Nome produto" value={nome} onChange={(e) => {setNome(e.target.value)}} variant="outlined" className={styles.textFielPadTop}/>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <TextField fullWidth  label="Valor" value={valor} onChange={(e) => {setValor(e.target.value)}} variant="outlined" className={styles.textFielPadTop}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField fullWidth  label="Descricao" value={descricao} onChange={(e) => {setDescricao(e.target.value)}} variant="outlined" className={styles.textFielPadTop}/>
                            </Grid>
                            <Grid item xs={12} md={7}></Grid>
                            <Grid item xs={12} md={3}>
                                <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} margin="dense" onClick={criarProduto}>SALVAR</Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button fullWidth variant="contained" size="large" className={styles.buttonLimpar} margin="dense" onClick={limparCadastro}>LIMPAR</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} className={styles.ProdutosTable}>
                <Box>
                    <Grid container className={styles.buttonsTable} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <h3>Filtros</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAtivos} disabled={filtroEtapa === 1}>Ativos</Button>
                                </Grid>                                  
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterInativos} disabled={filtroEtapa === 0}>Inativos</Button>
                                </Grid>                  
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={semFiltro} disabled={filtroEtapa === ""}>Todos</Button>
                                </Grid>                  
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} md={5}>
                            <h3>Botões de ação (selecione uma linha para ativar)</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={7} md={4}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonEncaminharOrc}  disabled={rowSelect.selectedCount === 0}>Inativar</Button>
                                </Grid>                  
                                <Grid item xs={5} md={4}>
                                    <Button fullWidth variant="contained" size="large" className={styles.buttonRecusarOrc}  disabled={rowSelect.selectedCount === 0}>Ativar</Button>
                                </Grid>                  
                            </Grid>
                        </Grid> */}
                        </Grid>
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={pending}
                        />
                        {
                            pending ?
                            <></>
                            :
                            <Grid container className={styles.paginationTable} justifyContent="center">
                                <Grid xs={1}>
                                    {
                                    ((page * 10) - 10) == 0 ?
                                    <KeyboardArrowLeftIcon className={styles.arrowLeftDisabled} id="back"/>
                                    :
                                    <KeyboardArrowLeftIcon className={styles.arrowLeft} id="back" onClick={handlePageChange}/>
                                    }
                                </Grid>
                                <Grid xs={3} md={2} className={styles.numberPagination}>
                                    {(page * 10) - 10} a {(page * 10) > qtd ? qtd : (page * 10)} de {qtd}
                                </Grid>
                                <Grid xs={1}>
                                    {
                                    (page * 10) >= qtd ?
                                    <KeyboardArrowRightIcon className={styles.arrowRightDisabled} id="next"/>
                                    :
                                    <KeyboardArrowRightIcon className={styles.arrowRight} id="next" onClick={handlePageChange}/>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Box>
            </Grid>
        </Grid>
    )
}