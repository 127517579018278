import { BarcodeScanner, } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'
import api from '../../../../services/api_credenciamento';
import { useState } from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { TextField, Grid } from "@mui/material"
 
export default () => {
  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [cpfCnpjValidados, setCpfCnpjValidados] = useState([]);

  function VerifyUser(e) {
    const newCpfCnpj = e.target.value;
    setCpfCnpj(newCpfCnpj)
    if(newCpfCnpj === '') {
        return;
    }
    if(newCpfCnpj.length < 11 || (newCpfCnpj.length > 11 && newCpfCnpj.length < 14)) {
        return;
    }
    if(newCpfCnpj.length > 14) {
        setCpfCnpj("");
        return;
    }
    setLoading(true)
    api
    .post("/registrar-acesso", {
        cpf_cnpj: newCpfCnpj
    })
    .then((res) => {
        const response = res.data;
        
        if(response.erro === 0) {
            cpfCnpjValidados.push(newCpfCnpj)
            setCpfCnpj("")
        } else {
            alert("Erro ao registrar acesso, tente novamente")
        }

        setLoading(false)
    })
    .catch((err) => {
        setLoading(false)
        setCpfCnpj("")
        console.error("ops! ocorreu um erro" + err);
    });
  }
  function handleCpfCnpj(e) {
    setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
  }
  return (
      loading ? 
      <>
        <AutorenewIcon/>
        <span>Registrando acesso...</span>
      </> 
      :
      <>
        <p></p>
        <Grid container>
          <Grid xs={12} style={{textAlign: 'center'}}>
            <h1>VALIDAÇÃO DE ACESSO</h1>
          </Grid>
          <Grid xs={3}></Grid>
          <Grid xs={6}>
            <TextField fullWidth autoFocus value={cpfCnpj} onChange={VerifyUser}>Agência</TextField>
          </Grid>
          <Grid xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
              <h2 style={{fontSize: '15px'}}>ÚLTIMOS CPF/CNPJ'S VALIDADOS</h2>
              {cpfCnpjValidados.map((cpfCnpjValidado) => {
                return <p>{cpfCnpjValidado}</p>
              })}
          </Grid>
        </Grid>
      </>
    //   <BarcodeScanner onCapture={VerifyUser} options={{ formats: ['code_128'] }}/>
  )
}