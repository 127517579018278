import { Grid, Button, TextField, FormControl, FormControlLabel, FormLabel, Box, Autocomplete, RadioGroup, Radio } from "@mui/material"
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip'
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function PaginaOrcamentosExpositor() {
    const [filtroEtapa, setFiltroEtapa] = useState(2);
    const columns = [
        {
            name: filtroEtapa === 1 || filtroEtapa === 2 ? 'Aprovar/Rep.' : "Observação Final",
            button: true,
            width: '100px',
            omit: filtroEtapa === 3 ? true : false,
            cell: (row) =>  row.etapa === "1" || row.etapa == 1 || row.etapa === "2" || row.etapa == 2 
                            ?
                            <Button 
                            className={styles.acaoOrcamento}
                            value={row.id} onClick={handleClickOpen}>Aprovar/Rep.</Button>
                            :
                            row.etapa === 3 ||  row.etapa === "3" 
                            ?
                            <></>
                            :
                            <span title={row.observacao_final} className={styles.obsFinal}>Ob. Final</span>
        },
        {
            name: 'Nº Orçamento',
            button: true,
            cell: (row) =>  <>
                                <span id={"id-" + row.id} title={row.observacao}>{row.id}</span>   
                            </>
        },
        { name: 'Expositor', selector: row => row.nome_expositor },
        { name: 'CPF/CNPJ', selector: row => row.cpf_cnpj },
        { name: 'Nome', selector: row => row.nome_usuario },
        {
            name: 'Telefone',
            button: true,
            cell: (row) => <a target="blank" id={row.telefone} href={"https://wa.me/"+row.telefone}>{row.telefone}</a>   
        },
        { name: 'Agência', selector: row => row.nome_agencia },
        { name: 'Nome Gerente', selector: row => row.nome_gerente },
        { name: 'Valor', selector: row => row.valor_format },
        { name: 'Etapa', selector: row => row.etapa_descricao },
        {
            name: 'Arquivo',
            button: true,
            cell: (row) => row.name_file === "" || row.name_file === null
                            ?
                            <></>
                            :
                            <a id={row.name_file} href={location+"/api/v1/files/arquivo/"+row.name_file}>Baixar Arquivo</a>  
        },
    ];
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [nome, setNome] = useState('');
    const [produto, setProduto] = useState('');
    const [valor, setValor] = useState('');
    const [formaPag, setFormaPag] = useState('');
    const [telefone, setTelefone] = useState('');
    const [agencia, setAgencia] = useState('');
    const [agenciaId, setAgenciaId] = useState('');
    const [agencias, setAgencias] = useState([]);
    const [expositorId, setExpositorId] = useState('');
    const [expositor, setExpositor] = useState('');
    const [expositores, setExpositores] = useState([]);
    const [observacao, setObservacao] = useState('');
    const [showCadOrcamento, setShowCadOrcamento] = useState(true);
    const [produtos, setProdutos] = useState([]);
    const [data, setData] = useState([]);
    const [qtd, setQtd] = useState(0);
    const [pending, setPending] = useState(true);
    const [page, setPage] = useState(1);
    const [rowSelect, setRowSelect] = useState({'selectedCount': 0, 'selectedRows': []});
    const [modal, setModal] = useState(false);
    const [idModal, setIdModal] = useState('');
    const [novaEtapaModal, setNovaEtapaModal] = useState(4);
    const [observacaoModal, setObservacaoModal] = useState("");
    const [location, setLocation] = useState('')

    useEffect(()=>{
        functionInitial();
        if(window.location.protocol == 'http') {
            setLocation(window.location.origin + ':3006');
        } else {
            setLocation(window.location.origin + ':3001');
        }
    },[])
    
    async function functionInitial() {
        await getExpositores()
        await getAgencias();
        await getOrcamentos(filtroEtapa);
    }

    function handleClickOpen(e) {
        setIdModal(e.target.value);
        setModal(true);
    };

    function handleInputCpfCnpj(e) {
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }

    function handleInputTelefone(e) {
        setTelefone(e.target.value.replace(/[^0-9]/g, ''));
    }

    function handleInputProduto(e) {
        setProduto(e.target.value);
    }

    function handleClose() {
        setModal(false);
        limparModal();
    };

    function limparModal() {
        setObservacaoModal("");
        setNovaEtapaModal("4");
        setIdModal("");
    }

    const handleEtapaModal = (event) => {
        setNovaEtapaModal(event.target.value);
    };

    const handleInputObservacaoModal = (event) => {
        setObservacaoModal(event.target.value);
    };

    function saveChange() {

    }

    function showCadastroOrcamento() {
        setShowCadOrcamento(!showCadOrcamento);
    }

    async function updateOrcamento() {
        if(idModal == "" || idModal == null) {
            alert("Ocorreu um erro, favor atualizar a página e tentar novamente.");
            return false;
        }
        var alerta = "";
        if(observacaoModal == "" || observacaoModal == null) {
            alerta += "Observação\n";
        }
        if(novaEtapaModal == "" || novaEtapaModal == null) {
            alerta += "Informar se irá Aprovar ou Reprovar\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
        try {
            await api
            .put("/orcamentos", {
                id: idModal,
                etapa: novaEtapaModal,
                observacao_final: observacaoModal
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        alert("Orçamento atualizado com sucesso.")
                        handleClose()
                        getOrcamentos(filtroEtapa)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamento, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamento, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
    }

    async function getAgencias() {
        try {
            await api
            .get("/agencia")
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setAgencias(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
    }

    async function getExpositores(situacaoUsuario) {
        setPending(true)
        setPage(1)
        try {
            await api
            .post("/usuario-expositor", {
                'situacao': ""
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setExpositores(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function getProdutosAtivos() {
        try {
            await api
            .post("/produtos/expositor", {
                'situacao': 1
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setProdutos(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                // alert("Sessão expirada, gentileza realizar o login novamente")
                // deleteCookie('token');
                // deleteCookie('exp');
                // deleteCookie('userType');
                // window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
    }

    function handleRowSelect (e) {
        setRowSelect(e)
    }

    function filterPerdente() {
        setFiltroEtapa(6)
        getOrcamentos(6)
    }

    function filterAprovado() {
        setFiltroEtapa(4)
        getOrcamentos(4)
    }

    function filterReprovado() {
        setFiltroEtapa(5)
        getOrcamentos(5)
    }

    function filterFinalizado() {
        setFiltroEtapa(3)
        getOrcamentos(3)
    }

    function filterAnaliseAgencia() {
        setFiltroEtapa(2)
        getOrcamentos(2)
    }


    function encaminhaOrcamentoAgencia() {
        if(rowSelect.selectedCount > 0) {
            atualizaEtapaOrcamento(2)
        }
    }

    function recusaOrcamento() {
        if(rowSelect.selectedCount > 0) {
            atualizaEtapaOrcamento(8)
        }
    }

    function getIdRowSelect() {
        var ids = []
        rowSelect.selectedRows.forEach(element => {
            ids.push(element.id)
        });
        return ids;
    }

    async function getOrcamentos(etapa, idAgencia, idExpositor) {
        setPending(true)
        setPage(1)
        let agencia = idAgencia === undefined || idAgencia === null ? agenciaId : idAgencia;
        setAgenciaId(agencia);
        try {
            await api
            .post("/orcamentos/paginate", {
                'offset': 0,
                'limit': 10,
                'etapa': etapa,
                'expositor': idExpositor === undefined || idExpositor === null ? expositorId : idExpositor,
                'agencia': agencia
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                        setQtd(response.qtd)
                        setData(response.data)
                        // props.atualizado()
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function handlePageChange(e) {
        var newPage = 0;
        if(e.target.id === "back") {
            newPage = page - 1;
            await setPage(page - 1);
        } else {
            newPage = page + 1;
            await setPage(page + 1);
        }

        setPending(true)

        const offset = (newPage * 10) - 10;
        
        try {
            await api
            .post("/orcamentos/paginate", {
                'offset': offset,
                'limit': 10,
                'etapa': filtroEtapa,
                'expositor': expositorId,
                'agencia': agenciaId
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                        setQtd(response.qtd)
                        setData(response.data)
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao buscar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          console.error("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    };

    async function atualizaEtapaOrcamento(etapa) {
        setPending(true)
        const id = getIdRowSelect();
        
        try {
            await api
            .put("/orcamentos/expositor", {
                'id': id,
                'etapa': etapa
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
        
                    } else if(response.erro !== 0) {
                        alert("Erro ao atualizar orçamentos, contate o suporte.");
                    } else {
                        alert("Erro ao atualizar orçamentos, contate o suporte.")
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    return (
        <Grid container>
            {/* <Grid xs={12} className={[styles.cadastroOrcamento , !showCadOrcamento ? styles.cadastroOrcamentoHidden : '']}>
                <Grid container className={styles.cadastroOrcamentoTopBar} onClick={showCadastroOrcamento}>
                    <Grid xs={10} className={styles.cadastroOrcamentoTitle}>
                        <h2>Cadastrar negócio/orçamento</h2>
                    </Grid>
                    <Grid xs={2} className={[styles.cadastroOrcamentoIcon]}>
                        <span><KeyboardArrowDownIcon/></span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={[styles.cadastroOrcamentoSection]}>
                    <Grid item xs={12} md={4}>
                        <InputMask
                            mask={cpfCnpj.length < 12 ? "999.999.999-999" : "99.999.999/9999-99"}
                            maskChar=""
                            value={cpfCnpj}
                            onChange={handleInputCpfCnpj}
                        >
                            <TextField fullWidth  label="CPF/CNPJ" variant="outlined"/>
                        </InputMask>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonValidar} margin="dense">VALIDAR</Button>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField fullWidth  label="Nome / Razão Social" variant="outlined" value={nome} onChange={(event) => {setNome(event.target.value)}}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Produto</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={produto}
                                    label="Produto"
                                    onChange={handleInputProduto}
                                    >
                                    {produtos.map((produto) => {
                                        return <MenuItem key={produto.id} value={produto.id}>{produto.nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField fullWidth  label="Valor Orçamento" value={valor} onChange={(event) => {setValor(event.target.value)}} variant="outlined" className={styles.textFielPadTop}/>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Forma de Pagamento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formaPag}
                                    label="Forma de Pagamento"
                                    onChange={(event) => {setFormaPag(event.target.value)}}
                                >
                                    <MenuItem value={1}>Financiamento Credcooper</MenuItem>
                                    <MenuItem value={2}>Direto com o Expositor</MenuItem>
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <InputMask
                            mask={"(99) 9 9999-9999"}
                            maskChar=""
                            value={telefone}
                            onChange={handleInputTelefone}
                        >
                            <TextField fullWidth  label="Telefone" variant="outlined" className={styles.textFielPadTop}/>
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Agência</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={agencia}
                                    label="Agência"
                                    onChange={(event) => {setAgencia(event.target.value)}}
                                >
                                    {agencias.map((agencia) => {
                                        return <MenuItem key={agencia.id} value={agencia.id}>{agencia.nome}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth  label="Observação" variant="outlined" className={styles.textFielPadTop} value={observacao} onChange={(event) => {setObservacao(event.target.value)}}/>
                    </Grid>
                    <Grid item xs={12} md={7}>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} onClick={criarOrcamento} margin="dense">SALVAR</Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button fullWidth variant="contained" size="large" className={styles.buttonLimpar} onClick={limparCadastro} margin="dense">LIMPAR</Button>
                    </Grid>
                </Grid>
            </Grid> */}
            <Grid xs={12} className={styles.orcamentosTable}>
                <Box>
                    <Grid container className={styles.buttonsTable} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <h3>Filtros</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAnaliseAgencia} disabled={filtroEtapa === 2}>Análise agência</Button>
                                </Grid>                  
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterAprovado} disabled={filtroEtapa === 4}>Aprovado</Button>
                                </Grid>                  
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterReprovado} disabled={filtroEtapa === 5}>Reprovado</Button>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={filterFinalizado} disabled={filtroEtapa === 3}>Finalizado</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className={styles.filtrosSelect}>
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        id="expositores-orcamentos"
                                        options={agencias.map((option) => ({label: option.nome, id: option.id}))}
                                        value={agencia}
                                        onChange={async (event, newValue) => {
                                            if(newValue !== null) {
                                                await setAgencia(newValue.label);
                                                await setAgenciaId(newValue.id);
                                                getOrcamentos(filtroEtapa, newValue.id, expositorId)
                                            } else {
                                                await setAgencia("");
                                                await setAgenciaId("");
                                                getOrcamentos(filtroEtapa, "", expositorId)
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} value={params.id} label="Agência" />
                                        )}
                                    />
                                </Grid>                                 
                                <Grid item xs={12} md={4}>
                                    <Autocomplete
                                        fullWidth
                                        id="expositores-orcamentos"
                                        options={expositores.map((option) => ({label: option.nome, id: option.id}))}
                                        value={expositor}
                                        onChange={(event, newValue) => {
                                            if(newValue !== null) {
                                                setExpositor(newValue.label);
                                                setExpositorId(newValue.id);
                                                getOrcamentos(filtroEtapa, agenciaId, newValue.id)
                                            } else {
                                                setExpositor("");
                                                setExpositorId("");
                                                getOrcamentos(filtroEtapa, agenciaId, "")
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} value={params.id} label="Expositor" />
                                        )}
                                    />
                                </Grid>                                 
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={styles.observacoes}>
                        <span>*Passe o mouse sobre "Nº Orçamento" para ver a descrição informada pelo expositor</span><br/>
                        { filtroEtapa !== 1 && filtroEtapa !== 2 && filtroEtapa !== 3 ? <span>**Passe o mouse sobre "Ob. Final" para ver o parecer final da agência</span> : <></>}
                    </Grid>
                    <DataTable
                        columns={columns}
                        data={data}
                        // selectableRows
                        onSelectedRowsChange={handleRowSelect}
                        selectableRowDisabled={row => row.etapa !== 6}
                        progressPending={pending}
                    />
                    {
                        pending ?
                        <></>
                        :
                        <Grid container className={styles.paginationTable} justifyContent="center">
                            <Grid xs={1}>
                                {
                                ((page * 10) - 10) == 0 ?
                                <KeyboardArrowLeftIcon className={styles.arrowLeftDisabled} id="back"/>
                                :
                                <KeyboardArrowLeftIcon className={styles.arrowLeft} id="back" onClick={handlePageChange}/>
                                }
                            </Grid>
                            <Grid xs={3} md={2} className={styles.numberPagination}>
                                {(page * 10) - 10} a {(page * 10) > qtd ? qtd : (page * 10)} de {qtd}
                            </Grid>
                            <Grid xs={1}>
                                {
                                (page * 10) >= qtd ?
                                <KeyboardArrowRightIcon className={styles.arrowRightDisabled} id="next"/>
                                :
                                <KeyboardArrowRightIcon className={styles.arrowRight} id="next" onClick={handlePageChange}/>
                                }
                            </Grid>
                        </Grid>
                    }
                </Box>
            </Grid>
            <Dialog 
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={modal}
                fullWidth
                maxWidth="80%"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Aprovar/Reprovar Orçamento
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                <CloseIcon />
                </IconButton>
                <DialogContent className={styles.modalContent}>
                    <Grid>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={novaEtapaModal}
                                onChange={handleEtapaModal}
                            >
                                <FormControlLabel value="4" control={<Radio />} label="Aprovar" />
                                <FormControlLabel value="5" control={<Radio />} label="Reprovar" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <form noValidate autoComplete="off">
                            <TextField multiline rows={4} fullWidth label="Observação" type="text" variant="outlined" value={observacaoModal} onChange={handleInputObservacaoModal} className={styles.observacaoModal}/>
                        </form>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button value={idModal} autoFocus onClick={updateOrcamento}>
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}