import { InputLabel, MenuItem, FormControl, Box, Grid, TextField, Button } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
// import doodle from '../../assets/app.webp'
import molduraFenasc from "../../assets/moldura.png"
import * as htmlToImage from 'html-to-image';

export default function Cadastro() {
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
    },[])

    async function downloadImage() {
        // setLoading(true);
        var node = document.getElementById('img-moldura-fenasc');
        
        await htmlToImage.toJpeg(node)
        .then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = 'fenasc.jpeg';
            link.href = dataUrl;
            link.click();
            // setLoading(false);
        });
        // setLoading(false);
    }

    async function setBackgroundImage(e) {
        setLoading(true);
        const file = e.target.files[0]
        if(file) {
            if (file.type.search('image') < 0) {
                alert("Arquivo inválido, permitido somente imagens");
            } else {
                var reader = new FileReader();
                var fileBase64 = null;
                reader.readAsDataURL(file);
                reader.onload = async function () {
                    fileBase64 = reader.result;
                    setImage(fileBase64);
                    setLoading(false);
                };
                
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    setImage("");
                    setLoading(false);
                };
            }
        } else {
            setImage("");
        }
    }

    // const LoadingIndicator = () => {
    //     const { promiseInProgress } = usePromiseTracker();
        
    //     return (
    //         <React.Fragment>
    //             {
    //                 promiseInProgress || loading &&
    //                 <div
    //                 style={{
    //                     width: "100%",
    //                     height: "100vh",
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     alignItems: "center",
    //                     position: "absolute",
    //                     background: "rgba(0,0,0,0.3)",
    //                     top: 0,
    //                     left: 0,
    //                     zIndex: 100
    //                 }}
    //                 >
    //                     <ThreeDots 
    //                         height="80"
    //                         width="80"
    //                         color="#00ae9d"
    //                         ariaLabel="circles-loading"
    //                         wrapperStyle={{}}
    //                         wrapperClass=""
    //                         visible={true}
    //                     />
    //                 </div>
    //             }
    //         </React.Fragment>
    //       );  
    // }

    return (
        <Box className={styles.background}>
            {/* <LoadingIndicator/> */}
            <Grid container justifyContent="center">
                <Grid item xs={10} md={6}>
                    <Grid container className={styles.boxCadastro}>
                        <Grid item xs={12} md={12} className={styles.formCadastro}>
                            <Grid container className={styles.moldura}>
                                <Grid item xs={12}>
                                    <img
                                    id="img-moldura-fenasc"
                                    src={molduraFenasc}
                                    className={styles.imgMolduraFenasc}
                                    style={{backgroundImage: "url("+image+")"}}
                                    alt="Picture of the author"
                                    />
                                </Grid>
                                <Grid item xs={12} className={styles.inputFile}>
                                    <TextField type="file" id='input-img-moldura-fenasc' className={styles.inputFileNone} onChange={setBackgroundImage}/>
                                </Grid>
                                <label htmlFor="input-img-moldura-fenasc" className={styles.labelInputFile}>Selecionar Foto</label>
                                <Grid item xs={12}>
                                    <Button onClick={downloadImage} className={styles.buttonDownload}>Download</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}