import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel, Box } from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api_credenciamento";
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'; 

export default function PaginaExpositorsExpositor() {
    const [cupomSelect, setCupomSelect] = useState('')
    const [disableButtons, setDisableButtons] = useState(false)

    useEffect(()=>{
        
    },[])

    function limparSorteador() {
        setCupomSelect("")
        setDisableButtons(false)
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    function sortearCupom(duration, array, categoria) {
        setDisableButtons(true)
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }
            const progress = Math.min((timestamp - startTimestamp) / duration);

            var positionArray = getRandomInt(0, array.length)
            setCupomSelect(array[positionArray])
            
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                if(categoria === 1) {
                    updateCuponsA(array[positionArray])
                } else if (categoria === 2) {
                    updateCuponsB(array[positionArray])
                }
            }
        };
        window.requestAnimationFrame(step);
    }

    async function sortearA() {
        await api
        .get("/cupons/categoria-a")
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                if(response.qtd === 0) {
                    alert("Não há cupons para sortear")
                } else {
                    const arr = response.data
                    sortearCupom(3000, arr, 1);
                }
            } else if(response.erro !== 0) {
                alert("Erro ao buscar cupons, contate o suporte");
            } else {
                alert("Erro ao buscar cupons, contate o suporte")
            }
        })
        .catch((err) => {
            // if(!err.response.data.auth) {
            // alert("Sessão expirada, gentileza realizar o login novamente")
            // deleteCookie('token');
            // deleteCookie('exp');
            // deleteCookie('userType');
            // window.location.href = "/login";
            // }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function sortearB() {
        await api
        .get("/cupons/categoria-b")
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                if(response.qtd === 0) {
                    alert("Não há cupons para sortear")
                } else {
                    const arr = response.data
                    sortearCupom(3000, arr, 2);
                }
            } else if(response.erro !== 0) {
                alert("Erro ao buscar cupons, contate o suporte");
            } else {
                alert("Erro ao buscar cupons, contate o suporte")
            }
        })
        .catch((err) => {
            // if(!err.response.data.auth) {
            // alert("Sessão expirada, gentileza realizar o login novamente")
            // deleteCookie('token');
            // deleteCookie('exp');
            // deleteCookie('userType');
            // window.location.href = "/login";
            // }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function updateCuponsA(cupomSorteado) {
        await api
        .put("/cupons/categoria-a", {
            'cpf_cnpj': cupomSorteado.cpf_cnpj,
            'cupom': cupomSorteado.cupom,
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                alert("Sorteio realizado com sucesso, parabéns ao ganhador!");
            } else if(response.erro !== 0) {
                alert("Erro ao registrar cupons, contate o suporte.");
            } else {
                alert("Erro ao registrar cupons, contate o suporte.")
            }
        })
        .catch((err) => {
            // if(!err.response.data.auth) {
            // alert("Sessão expirada, gentileza realizar o login novamente")
            // deleteCookie('token');
            // deleteCookie('exp');
            // deleteCookie('userType');
            // window.location.href = "/login";
            // }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    async function updateCuponsB(cupomSorteado) {
        await api
        .put("/cupons/categoria-b", {
            'cpf_cnpj': cupomSorteado.cpf_cnpj,
            'cupom': cupomSorteado.cupom,
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                alert("Sorteio realizado com sucesso, parabéns ao ganhador!");
            } else if(response.erro !== 0) {
                alert("Erro ao registrar cupons, contate o suporte.");
            } else {
                alert("Erro ao registrar cupons, contate o suporte.")
            }
        })
        .catch((err) => {
            // if(!err.response.data.auth) {
            // alert("Sessão expirada, gentileza realizar o login novamente")
            // deleteCookie('token');
            // deleteCookie('exp');
            // deleteCookie('userType');
            // window.location.href = "/login";
            // }
            console.error("ops! ocorreu um erro" + err);
        });
    }

    return (
        <Grid container>
            <Grid xs={12} className={styles.ExpositorsTable}>
                <Box>
                    <Grid container className={styles.buttonsTable} spacing={2}>
                    { !disableButtons ?
                        <Grid item xs={12} md={12}>
                            <h3>Sorteadores</h3>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={sortearA}>Categoria A</Button>
                                </Grid>                                  
                                <Grid item xs={6} md={6}>
                                    <Button fullWidth variant="contained" size="large" className={styles.filtroEtapa} onClick={sortearB}>Categoria B</Button>
                                </Grid>                                   
                            </Grid>
                        </Grid>
                        :
                        <Grid container className={styles.contentSorteio}>
                            <Grid item xs={12} md={12} className={styles.headerSorteio}>
                                <span>
                                    CUPOM
                                </span>
                                <span> - </span>
                                <span>
                                    NOME
                                </span>
                            </Grid>
                            <Grid item xs={12} md={12} className={styles.bodySorteio}>
                                <span>
                                    {cupomSelect.cupom}
                                </span>
                                <span> - </span>
                                <span>
                                    {cupomSelect.nome}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={12} className={styles.footerSorteio}>
                                <Button onClick={limparSorteador}>Voltar</Button>
                            </Grid>
                        </Grid>
                    }
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}