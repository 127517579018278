// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_navPainel__vgb3f { /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(46deg, #7db61c, #c9d200); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  margin-top: 25px;
  padding: 10px;
  border-radius: 5px;
}
.styles_navPainel__vgb3f span {
  color: #FFF;
  font-family: "Sicoob Black";
  text-transform: uppercase;
}

.styles_sectionRoute__I\\+3eE {
  margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Painel/Expositor/styles.module.scss"],"names":[],"mappings":"AAAA,2BACI,+BAAA;EAAsH,oDAAA,EAAA,qEAAA;EACtH,gBAAA;EACA,aAAA;EACA,kBAAA;AAEJ;AAAI;EACI,WAAA;EACA,2BAAA;EACA,yBAAA;AAER;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".navPainel {\r\n    background: -webkit-linear-gradient(46deg, #7db61c,#c9d200);/* Chrome 10-25, Safari 5.1-6 */                          background: linear-gradient(46deg, #7db61c,#c9d200);/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ \r\n    margin-top: 25px;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n\r\n    span {\r\n        color: #FFF;\r\n        font-family: \"Sicoob Black\";\r\n        text-transform: uppercase;\r\n    }\r\n}\r\n\r\n.sectionRoute {\r\n    margin-top: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navPainel": `styles_navPainel__vgb3f`,
	"sectionRoute": `styles_sectionRoute__I+3eE`
};
export default ___CSS_LOADER_EXPORT___;
