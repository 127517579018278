import axios from "axios";
import { getCookie } from 'cookies-next';
const currentUrl = window.location.href;

const api = axios.create({
    baseURL: currentUrl.search("https") >= 0 ? `https://fenasc.com:3001/api/v1` : `http://fenasc.com:3006/api/v1`,
    headers: {
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "X-Requested-With, Content-Type, Accept, X-Token",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
    //   "Authorization": getCookie('token')
    }
  });

export default api;