import { Grid, Button, TextField, FormControl, Select, MenuItem, InputLabel, Box } from "@mui/material"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DataTable from 'react-data-table-component';
import styles from './styles.module.scss'
import InputMask from 'react-input-mask';
import { useEffect, useState } from "react";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import api from "../../../../services/api";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator'; 

export default function PaginaNegocios() {
    const columns = [
        { name: 'ID', selector: row => row.id, maxWidth: '50px' },
        { name: 'CPF/CNPJ', selector: row => row.cpf_cnpj, maxWidth: '200px' },
        { name: 'Descrição Negócio', selector: row => row.descricao_negocio },
    ];
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [tipoNegocio, setTipoNegocio] = useState("1");
    const [showCadNegociacao, setShowCadNegociacao] = useState(true);
    const [data, setData] = useState([]);
    const [qtd, setQtd] = useState(1);
    const [pending, setPending] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(()=>{
        // if(getCookie('userType') === '5') {
        //     window.location.href = "/painel-colaborador";
        // }
        functionInitial();
    },[])

    async function functionInitial() {
        await getNegocios();
    }

    function showCadastroNegociacao() {
        setShowCadNegociacao(!showCadNegociacao);
    }

    function handleCpfCnpj(e) {
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }

    function limparCadastro() {
        setCpfCnpj("");
        setTipoNegocio("1");
    }

    async function createNegociacao() {
        var alerta = "";
        if(cpfCnpj == "" || cpfCnpj == null) {
            alerta += "CPF\n";
        }
        if(tipoNegocio == "" || tipoNegocio == null) {
            alerta += "Tipo Negócio\n";
        }
        if(alerta != "") {
            alert("Favor informar os dados básicos necessários:\n" + alerta);
            return false;
        }
        if(!cpfValidator.isValid(cpfCnpj) && !cnpjValidator.isValid(cpfCnpj)) {
            alert('CPF/CNPJ inválido');
            return;
        }
        
        try {
            const userId = getCookie('userId')
            await api
            .post("/negociacao-cooperativa/create", {
                'cpf_cnpj': cpfCnpj,
                'tipo_negocio': tipoNegocio,
                'id_usuario': userId,
            })
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        alert('Cadastro realizado com sucesso!')
                        getNegocios()
                        limparCadastro()
                    } else if(response.erro !== 0) {
                        alert(response.mensagem);
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    async function getNegocios() {
        setPending(true)
        setPage(1)
        
        try {
            await api
            .get("/negociacao-cooperativa")
            .then((res) => {
                if(res.data) {
                    const response = res.data;
                    
                    if(response.erro === 0) {
                        setQtd(response.qtd)
                        setData(response.data)
                    } else if(response.erro !== 0) {
                        alert("Erro ao buscar orçamentos, contate o suporte.");
                    }
                }
            })
            .catch((err) => {
                // if(!err.response.data.auth) {
                //     alert("Sessão expirada, gentileza realizar o login novamente")
                //     deleteCookie('token');
                //     deleteCookie('exp');
                //     deleteCookie('userType');
                //     window.location.href = "/login";
                // }
                console.error("ops! ocorreu um erro" + err);
            });
        } catch (e) {
          alert("Ocorreu um erro, contate o suporte.")
        }
        setPending(false)
    }

    return (
        <Grid container>
            <Grid xs={12} className={[styles.cadastroFuncionario , !showCadNegociacao ? styles.cadastroFuncionarioHidden : '']}>
                <Grid container className={styles.cadastroFuncionarioTopBar} onClick={showCadastroNegociacao}>
                    <Grid xs={10} className={styles.cadastroFuncionarioTitle}>
                        <h2>Cadastrar negócio</h2>
                    </Grid>
                    <Grid xs={2} className={[styles.cadastroFuncionarioIcon]}>
                        <span><KeyboardArrowDownIcon/></span>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={[styles.cadastroFuncionarioSection]}>
                    <Grid item xs={12} md={4}>
                        <InputMask
                            mask={cpfCnpj.length < 12 ? "999.999.999-999" : "99.999.999/9999-99"}
                            maskChar=""
                            value={cpfCnpj}
                            onChange={handleCpfCnpj}
                        >
                            <TextField fullWidth label="CPF/CNPJ" variant="outlined" className={styles.textFielPadTop}/>
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DemoContainer components={["Select"]}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Tipo Negócio</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tipoNegocio}
                                    label="Tipo Negócio"
                                    onChange={(event) => {setTipoNegocio(event.target.value)}}
                                >
                                    <MenuItem value="1">Crédito</MenuItem>
                                    <MenuItem value="2">Produto</MenuItem>
                                </Select>
                            </FormControl>
                        </DemoContainer>
                    </Grid>
                    <Grid container spacing={2} className={[styles.cadastroFuncionarioButtons]} justifyContent={'flex-end'} alignItems={'flex-end'}>
                        <Grid item xs={12} md={3} mdOffset={7}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonSalvar} onClick={createNegociacao} margin="dense">SALVAR</Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button fullWidth variant="contained" size="large" className={styles.buttonLimpar} onClick={limparCadastro} margin="dense">LIMPAR</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} className={styles.FuncionariosTable}>
                <Box>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={pending}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}