import { Box, Grid, Button, TextField } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import doodle from '../../assets/app.webp'
import InputMask from 'react-input-mask';
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import api from "../../services/api";

export default function Credenciamento() {
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [cupons, setCupons] = useState([]);

    useEffect(()=>{
    },[])

    function handleInputCpfCnpj(e) {
        // alert(e.target.value.replace(/[^0-9]/g, ''))
        setCpfCnpj(e.target.value.replace(/[^0-9]/g, ''));
    }
    function addCpfCnpj(e) {
        setCpfCnpj(cpfCnpj + e.target.value);
    }
    function deleteCpfCnpj(e) {
        setCpfCnpj(cpfCnpj.substr(0, cpfCnpj.length - 1));
    }
    function clearCpfCnpj(e) {
        setCpfCnpj("");
    }
    function callVerifyUser() {
        VerifyUser(cpfCnpj)
    }
    async function buscarCupons() {
        if(cpfCnpj === '') {
            alert("Favor informar um CPF/CNPJ valido.")
            return;
        }
        if(cpfCnpj.length < 11 || (cpfCnpj.length > 11 && cpfCnpj.length < 14)) {
            alert("Favor informar um CPF/CNPJ valido.")
            return;
        }
        if(!cpf.isValid(cpfCnpj) && !cnpj.isValid(cpfCnpj)) {
            alert('CPF/CNPJ inválido');
            return;
        }
        await api
        .post("/cupons/cpf-cnpj", {
            'cpf_cnpj': cpfCnpj
        })
        .then((res) => {
            const response = res.data;
            
            if(response.erro === 0) {
                setCupons(response.data)
            } else {
                alert("Erro ao buscar os cupons, contate o suporte.")
            }
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }

    return (
        <Box>
            <Grid className={styles.banner}>
            
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{textAlign: 'center'}}>
                <h1>ACOMPANHE SEUS CUPONS</h1>
              </Grid>
              <Grid item xs={1} md={4}></Grid>
              <Grid item xs={7} md={3}>
                <TextField fullWidth autoFocus value={cpfCnpj} onChange={handleInputCpfCnpj}>Agência</TextField>
              </Grid>
              <Grid item xs={3} md={1}>
                <Button fullWidth variant="contained" size="large" className={styles.buttonBuscar} onClick={buscarCupons}>Buscar</Button>
              </Grid>
              <Grid xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
                  <h2 style={{fontSize: '15px'}}>CUPONS</h2>
                  {cupons.map((cupom) => {
                    return <p>{cupom.cupom}</p>
                  })}
              </Grid>
            </Grid>
        </Box>
    )
}