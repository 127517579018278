import { Container, Box, Grid, Button, Menu, MenuItem } from "@mui/material"
import styles from './styles.module.scss'
import { useEffect, useState } from "react";
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import MenuIcon from '@mui/icons-material/Menu';
import RoutesPainel from './Routes'
import { Link } from "react-router-dom";

export default function PainelExpositor() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageOpen, setpageOpen] = useState("Página Inicial");
    const [userType, setUserType] = useState("Página Inicial");
    const open = Boolean(anchorEl);

    const linkStyle = {
        textDecoration: "none",
        color: 'black'
    };

    useEffect(()=>{
        
    },[])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        if(event.target.outerText !== "") {
            setpageOpen(event.target.outerText)
        }
    };

    return (
        <Box className={styles.background}>
            <Container>
                <Grid container>
                    <Grid xs={12} className={styles.navPainel}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MenuIcon sx={{ color: "#fff" }}/>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                             <Link to="/painel-colaborador" style={linkStyle}>
                                <MenuItem value="Página Inicial" onClick={handleClose}>Página Inicial</MenuItem>
                             </Link>
                             <Link to="/painel-colaborador/orcamentos" style={linkStyle}>
                                <MenuItem value="Orçamentos" onClick={handleClose}>Orçamentos</MenuItem>
                             </Link>
                             <Link to="/painel-colaborador/negociacao" style={linkStyle}>
                                <MenuItem value="Cadastrar Negociação" onClick={handleClose}>Cadastrar Neg.</MenuItem>
                             </Link>
                             {/*{ 
                                userType === 3 || userType === "3" ?
                                <>
                                    <Link to="/painel-colaborador/expositores" style={linkStyle}>
                                        <MenuItem value="Expositores" onClick={handleClose}>Expositores</MenuItem>
                                    </Link>
                                    <Link to="/painel-colaborador/funcionarios" style={linkStyle}>
                                        <MenuItem value="Funcionários" onClick={handleClose}>Funcionários</MenuItem>
                                    </Link>
                                    <Link to="/painel-colaborador/validar-credenciamento" style={linkStyle}>
                                        <MenuItem value="Validar Credenciamento" onClick={handleClose}>Validar Cred.</MenuItem>
                                    </Link>
                                    <Link to="/painel-colaborador/sorteador" style={linkStyle}>
                                        <MenuItem value="Sorteador" onClick={handleClose}>Sorteador</MenuItem>
                                    </Link>
                                    <Link to="/painel-colaborador/foto-expositor" style={linkStyle}>
                                        <MenuItem value="Foto Expositor" onClick={handleClose}>Foto Expositor</MenuItem>
                                    </Link>
                                </>
                                : 
                                <></> 
                            */}
                            
                        </Menu>
                        <span className={styles.navTitlePage}>{pageOpen}</span>
                    </Grid>
                    <Grid container className={styles.sectionRoute}>
                        <RoutesPainel></RoutesPainel>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}